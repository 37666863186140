import { Routes } from "@angular/router";

import { FullComponent } from "./layouts/full/full.component";
import { AppBlankComponent } from "./layouts/blank/blank.component";
import { CtAuthenticationGuard } from "@ctsolution/ct-authentication";
import {PagesModule} from "./pages/pages.module";
import {AuthenticationModule} from "./authentication/authentication.module";

export const AppRoutes: Routes = [
  {
    path: "",
    component: FullComponent,
    canActivate: [CtAuthenticationGuard],
    children: [
      {
        path: "",
        loadChildren: () => PagesModule,
      }
    ]
  },
  {
    path: "",
    component: AppBlankComponent,
    children: [
      {
        path: "authentication",
        loadChildren: () => AuthenticationModule,
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/authentication/login',
  },
];
