import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpecificContractDetailComponent} from "./specific-contract-detail.component";
import {SpecificContractDetailService} from "./specific-contract-detail.service";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {ContractExtraFieldModule} from "../contract-create/contract-extra-field/contract-extra-field.module";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";

@NgModule({
    declarations: [
        SpecificContractDetailComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        ContractExtraFieldModule,
        MatButtonModule
    ],
    exports: [
        SpecificContractDetailComponent
    ],
    providers: [
        SpecificContractDetailService
    ]
})
export class SpecificContractDetailModule {
}
