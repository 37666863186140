import {Component} from '@angular/core';
import {
    CtControlConfiguration,
    CtControlValidator,
    CtFormContainerHelper,
    CtThemeTypes
} from "@ctsolution/ct-framework";
import {SUPPLIER_CONTROL} from "./supplier.control";

@Component({
    selector: 'app-supplier-control',
    template: `
        <ct-control [configuration]="control"></ct-control>`
})
export class SupplierControlComponent {

    control: CtControlConfiguration = CtControlConfiguration
        .create(
            CtFormContainerHelper
                .create()
                .getCTFormControlByCTFieldInfo(SUPPLIER_CONTROL))
        .setTheme(CtThemeTypes.MATERIAL)
        .setValidators([
            CtControlValidator
                .create({name: 'required'} as CtControlValidator)
        ]);

}
