import { MediaMatcher } from "@angular/cdk/layout";
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output
} from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { RoleService } from "src/app/core/lib/role.service";
import { ChildrenItems } from "../menu/_classes/menu-item.interface";
import { MenuItems } from "../menu/menu-items";
import { Menu } from './../menu/_classes/menu-item.interface';

@Component({
    selector: "app-vertical-sidebar",
    templateUrl: "./vertical-sidebar.component.html",
    styleUrls: []
})
export class VerticalAppSidebarComponent implements OnDestroy, AfterViewInit {

    mobileQuery: MediaQueryList;

    @Input() showClass: boolean = false;
    @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _mobileQueryListener: () => void;
    itemSelect: number[] = [];

    // @ts-ignore
    menuItems$: BehaviorSubject<Menu[]> = new BehaviorSubject([]);

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        private menuItems: MenuItems,
        private router: Router,
        private roleService: RoleService
    ) {

        this.mobileQuery = media.matchMedia("(min-width: 768px)");
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener);

    }

    ngAfterViewInit() {

        this.menuItems
            .getMenuitem()
            .then(async (result: Menu[]) => {
                
                var isAdmin = await this.roleService.is.administrator()
                if (isAdmin) {
                    result.push({
                        "state": "report",
                        "name": "report",
                        "type": "link",
                        "icon": "report"
                    });
                }
                
                this.menuItems$
                    .next(result);

            });

    }

    handleNotify(menuitem: Menu, childitem?: ChildrenItems) {

        if (menuitem.state === "dashboard") {

            this.router.navigateByUrl("/");
            return;

        }

        let states: string[] = ["/"];

        if (childitem) {

            const currMenuItem = menuitem;

            menuitem = childitem as Menu;
            menuitem.separator = [{name: currMenuItem.state, type: "link"} as Menu];

        }

        if (menuitem.separator?.length) {

            states = states.concat(menuitem.separator.map((elm: any) => elm.name));

        }

        states.push(...(Array.isArray(menuitem.state) ? menuitem.state : [menuitem.state]));

        // Serve a ricaricare il component CtModel simulando la navigazione da un'altra parte
        this.router.navigateByUrl("/", {skipLocationChange: true})
            .then(() => {

                this.router
                    .navigate(states)
                    .then(() => {

                        if (window.innerWidth < 1024) {

                            this.notify.emit(!this.showClass);

                        }

                    });

            });

    }

    ngOnDestroy(): void {

        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener);

    }

}
