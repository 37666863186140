import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowHidePasswordToggleComponent } from './show-hide-password-toggle.component';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    ShowHidePasswordToggleComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule

  ],
  exports :[
    ShowHidePasswordToggleComponent
  ]
})
export class ShowHidePasswordToggleModule { }
