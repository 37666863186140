import {CtControlConfiguration, CtControlTypes, CtThemeTypes} from "@ctsolution/ct-framework";

export const MONTH_DURATION_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
    name: "MonthDuration",
    label: "MonthDuration",
    theme: CtThemeTypes.MATERIAL,
    validators: [
        {name: "required"},
        {name: "min", value: 1}
    ],
    // readonly: true,
    // disabled: true,
    type: CtControlTypes.COUNTER
};
