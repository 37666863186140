import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContractRenewFieldComponent} from "./contract-renew-field.component";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {CtButtonModule, CtControlModule} from "@ctsolution/ct-framework";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
    declarations: [
        ContractRenewFieldComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        CtControlModule,
        ReactiveFormsModule,
        CtButtonModule
    ],
    exports: [
        ContractRenewFieldComponent
    ]
})
export class ContractRenewFieldModule {
}
