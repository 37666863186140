import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContractComponent} from "./contract.component";
import {ContractCreateModule} from "./contract-create/contract-create.module";
import {RouterModule} from "@angular/router";
import {ContractCreateComponent} from "./contract-create/contract-create.component";
import {CtAttachmentManagerModule, CtModelModule, CtModelRouteData} from "@ctsolution/ct-framework";
import {FlexModule} from "@angular/flex-layout";
import {SpecificContractDetailModule} from "./specific-contract-detail/specific-contract-detail.module";
import {MatIconModule} from "@angular/material/icon";
import {ContractAttachmentsService} from "./contract-attachments.service";
import {FileController} from "../../core/controller/file.controller";
import {StateBulletModule} from "../../components/state-legend/state-bullet/state-bullet.module";
import {StateLegendModule} from "../../components/state-legend/state-legend.module";

export const CONTRACT_ROUTEDATA = (): CtModelRouteData => CtModelRouteData
    .create()
    .setController("contract");

@NgModule({
    declarations: [
        ContractComponent
    ],
    imports: [
        CommonModule,
        CtModelModule,
        ContractCreateModule,
        SpecificContractDetailModule,
        CtAttachmentManagerModule,
        RouterModule.forChild([
            {
                path: 'create',
                component: ContractCreateComponent,
                data: {
                    title: 'Contratti',
                    urls: [{title: 'Lista contratti'}, {title: 'Creazione contratto'}]
                }
            },
            {
                path: 'edit/:id',
                component: ContractCreateComponent,
                data: {
                    title: 'Contratti',
                    urls: [{title: 'Lista contratti'}, {title: 'Modifica contratto'}]
                }
            },
            {
                path: '',
                component: ContractComponent
            }
        ]),
        StateLegendModule,
        FlexModule,
        StateBulletModule,
        MatIconModule
    ],
    providers: [
        ContractAttachmentsService,
        FileController
    ]
})
export class ContractModule {
}
