import {Component} from '@angular/core';
import {CtControlConfiguration} from "@ctsolution/ct-framework";
import {ORDER_DATE_CONTROL} from "./order-date.control";

@Component({
    selector: 'app-order-date-control',
    template: `
        <ct-control [configuration]="control"></ct-control>`
})
export class OrderDateControlComponent {

    control: CtControlConfiguration = CtControlConfiguration.create(ORDER_DATE_CONTROL);

    ngOnInit() {

        this.setup();

    }

    setup() {

        // this.control
        //     .setValue(new Date());

    }

}
