import {Component} from '@angular/core';
import {
    CtControlConfiguration,
    CtControlValidator,
    CtFormContainerHelper, CtModelConfiguration, CtSelectControlOptions, CtSelectControlValue,
    CtThemeTypes
} from "@ctsolution/ct-framework";
import {COMMERCIAL_CONTROL} from "./commercial.control";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {ICommercial} from "../../../../../core/class/commercial";
import {JwtService} from "../../../../../core/lib/jwt.service";

@Component({
    selector: 'app-commercial-control',
    template: `
        <ct-control [configuration]="control"></ct-control>`
})
export class CommercialControlComponent {

    control: CtControlConfiguration = CtControlConfiguration
        .create(
            CtFormContainerHelper
                .create()
                .getCTFormControlByCTFieldInfo(COMMERCIAL_CONTROL))
        .setTheme(CtThemeTypes.MATERIAL)
        .setValidators([CtControlValidator.create({name: 'required'} as CtControlValidator)]);

    constructor(private jwtService: JwtService) {
    }

    ngOnInit() {

        this.setup();

    }

    async setup() {

        const commercialEmail: string | null = await this.jwtService.getUserEmail();

        this.control
            .setOptions(
                CtSelectControlOptions
                    .create()
                    .setLookupResponseMapper((response: CtWebapiGenericResponse<CtModelConfiguration<ICommercial>>) => {

                        if (!this.control.control?.value) {

                            const selectedValue: ICommercial | undefined = (<Array<ICommercial>>response.Result.DataSource)
                                .find((customer: ICommercial) => customer.Email === commercialEmail);

                            this.control
                                ?.setValue(selectedValue?.Code);

                        }

                        return (<Array<ICommercial>>response.Result.DataSource ?? [])
                            .map((elm: ICommercial) => {

                                return CtSelectControlValue.create()
                                    .setLabel(elm.Description)
                                    .setValue(elm.Code);

                            });

                    })
            )

    }

}
