<ct-model
        *ngIf="configuration"
        [configuration]="configuration"></ct-model>

<ng-template #StateCellTemplate let-row="row">

    <app-state-bullet
            [configuration]="bulletStateConfiguration(row.State)"></app-state-bullet>

</ng-template>

<ng-template #legendTemplate>

    <app-state-legend [configuration]="legendStateConfiguration()"></app-state-legend>

</ng-template>
