import {Component} from '@angular/core';
import {CtControlConfiguration} from "@ctsolution/ct-framework";
import {ORDER_NUMBER_CONTROL} from "./order-number.control";

@Component({
    selector: 'app-order-number-control',
    template: `
        <ct-control [configuration]="control"></ct-control>`
})
export class OrderNumberControlComponent {

    control: CtControlConfiguration = CtControlConfiguration.create(ORDER_NUMBER_CONTROL);

    ngOnInit() {

    }

}
