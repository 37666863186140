import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WarrantyListComponent} from './warranty-list.component';
import {CtModelModule} from "@ctsolution/ct-framework";
import {StateBulletModule} from "../../../components/state-legend/state-bullet/state-bullet.module";
import {StateLegendModule} from "../../../components/state-legend/state-legend.module";


@NgModule({
    declarations: [
        WarrantyListComponent
    ],
    imports: [
        CommonModule,
        CtModelModule,
        StateBulletModule,
        StateLegendModule
    ]
})
export class WarrantyListModule {
}
