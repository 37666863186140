import { Injectable } from "@angular/core";
import { CtModelConfiguration } from "@ctsolution/ct-framework";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class GeneralService {

  constructor(private router: Router) {
  }

  navigateOnCTModelRouter(controller: string[], action: "create" | "detail" | "edit", dto?: { value: any, configuration?: CtModelConfiguration<any>, qpKeys?: string[] }) {

    const commands: string[] = ["/", ...controller, action];
    const queryParams: any = {};

    if (dto?.value) {

      if ((dto.qpKeys ?? []).length) {

        dto.qpKeys
          ?.forEach(k => queryParams[k] = dto.value[k]);

      }

      const CTFieldInfosDTOKey: string | null = dto.configuration?.getCTFieldInfosDTOKey() ?? null;

      if (CTFieldInfosDTOKey && dto.value) {

        commands.push(dto.value[CTFieldInfosDTOKey]);

      }

    }

    this.router.navigate(commands, { queryParams });

  }

}
