import {CtControlConfiguration, CtControlTypes, CtThemeTypes} from "@ctsolution/ct-framework";

export const CONTRACT_STATE_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
    name: "State",
    label: "state",
    theme: CtThemeTypes.MATERIAL,
    type: CtControlTypes.ENUMERABLE,
    validators: [
        {name: "required"}
    ]
};
