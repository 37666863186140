<div class="blank-container">
  <div class="blank-container-box">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="text-center">
            <img alt="homepage" src="assets/images/logo/logo.png" height="55" />
            <h4 class="m-t-0">Registrati su {{viewModel.projectName}}</h4>
          </div>
          <div fxLayout="row wrap">
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-form-field style="width: 100%">
                <input
                  matInput
                  placeholder="Email"
                  type="email"
                  [formControl]="form.controls['email']"
                />
              </mat-form-field>
              <small
                *ngIf="
                  form.controls['email'].hasError('required') && form.controls['email'].touched
                "
                class="text-danger support-text"
              >
                * Campo obbligatorio
              </small>
              <small
                *ngIf="form.controls['email'].errors?.email && form.controls['email'].touched"
                class="text-danger support-text"
              >
                * Campo non valido
              </small>
            </div>
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" class="password-cntr">
              <mat-form-field style="width: 100%">
                <input
                  matInput
                  #passwordRegistration
                  type="password"
                  placeholder="Password"
                  [formControl]="form.controls['password']"
                />
                <show-hide-password-toggle (onChange)="passwordRegistration.type=$event? 'text' : 'password'"></show-hide-password-toggle>
              </mat-form-field>
              <small
                *ngIf="
                  form.controls['password'].hasError('required') &&
                  form.controls['password'].touched
                "
                class="text-danger support-text"
              >
                * Campo obbligatorio
              </small>
            </div>
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" class="password-cntr">
              <mat-form-field style="width: 100%">
                <input
                  matInput
                  #passwordControlRegistration
                  placeholder="Conferma Password"
                  [formControl]="form.controls['confirmPassword']"
                  type="password"
                />
                <show-hide-password-toggle (onChange)="passwordControlRegistration.type=$event? 'text' : 'password'"></show-hide-password-toggle>
              </mat-form-field>
              <small
                *ngIf="
                  form.controls['confirmPassword'].hasError('required') &&
                  form.controls['confirmPassword'].touched
                "
                class="text-danger support-text"
              >
                * Campo obbligatorio
              </small>
              <small
                *ngIf="form.controls['confirmPassword'].errors?.equalTo"
                class="text-danger support-text"
              >
                * Le due password non coincidono
              </small>
            </div>
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-checkbox class="font-14">
                Ho letto e accetto i
                <a href="javascript:void(0)" class="link text-info">termini e condizioni</a>.
              </mat-checkbox>
            </div>
            <button
              mat-raised-button
              color="primary"
              class="btn-block btn-lg m-t-20 m-b-20"
              type="submit"
              [disabled]="!form.valid"
            >
              Registrati
            </button>

            <div class="text-center" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <span
              >Hai già un account?
                <a [routerLink]="['/authentication/login']" class="link text-info"> Accedi </a>
              </span>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
