import {CtControlConfiguration, CtControlTypes, CtThemeTypes} from "@ctsolution/ct-framework";

export const INVOICE_TYPE_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
    name: "InvoiceType",
    label: "InvoiceType",
    theme: CtThemeTypes.MATERIAL,
    type: CtControlTypes.ENUMERABLE,
    validators: [
        {name: "required"}
    ]
};
