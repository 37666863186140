import {Component} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../environments/environment";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent {

    constructor(private snackbar: MatSnackBar, private translate: TranslateService) {

        translate.setDefaultLang("it");

    }

    ngOnInit() {

        if (!environment.production) {

            this.snackbar.open("Modalità di Sviluppo Attiva", "X", {duration: 3000});

        }

    }

}
