import {Component} from '@angular/core';
import {
    CtControlConfiguration,
    MateriaHintExtensionConfig, MaterialControlOptions
} from "@ctsolution/ct-framework";
import {VALIDITY_START_CONTROL} from "./validity-start.control";

@Component({
    selector: 'app-validity-start-control',
    template: `
        <ct-control [configuration]="control"></ct-control>`
})
export class ValidityStartControlComponent {

    control: CtControlConfiguration = CtControlConfiguration
        .create(VALIDITY_START_CONTROL);

    ngOnInit() {

        this.setup();

    }

    setup() {

        const firstDayOfNextMonth: Date = new Date();
        firstDayOfNextMonth.setDate(1);
        firstDayOfNextMonth.setMonth(firstDayOfNextMonth.getMonth() + 1);

        this.control
            .setValue(firstDayOfNextMonth);

        this.control
            .control
            ?.valueChanges
            .subscribe(value => {

                if (!this.control.materialOptions) this.control.setMaterialOptions(MaterialControlOptions.create());

                this.control
                    .materialOptions
                    ?.setHint(MateriaHintExtensionConfig
                        .create()
                        .setMessage(value.getTime() < new Date().getTime() ? 'Attenzione: la data inserita è antecedente alla data attuale.' : null))

            })

    }

}
