import {CtSelectControlValue} from "@ctsolution/ct-framework";
import {InvoiceType} from "../../../../../core/enum/invoice-type";

export const CONTRACT_INVOICE_TYPES: CtSelectControlValue[] = [
    CtSelectControlValue
        .create()
        .setLabel('Mensile Anticipata')
        .setValue(InvoiceType.MensileAnticipata),
    CtSelectControlValue
        .create()
        .setLabel('Bimestrale Anticipata')
        .setValue(InvoiceType.BimestraleAnticipata),
    CtSelectControlValue
        .create()
        .setLabel('Trimestrale Anticipata')
        .setValue(InvoiceType.TrimestraleAnticipata),
    CtSelectControlValue
        .create()
        .setLabel('Semestrale Anticipata')
        .setValue(InvoiceType.SemestraleAnticipata),
    CtSelectControlValue
        .create()
        .setLabel('Annuale Anticipata')
        .setValue(InvoiceType.AnnualeAnticipata),
    CtSelectControlValue
        .create()
        .setLabel('Biennale Anticipata')
        .setValue(InvoiceType.BiennaleAnticipata),
    CtSelectControlValue
        .create()
        .setLabel('Triennale Anticipata')
        .setValue(InvoiceType.TriennaleAnticipata),
    CtSelectControlValue
        .create()
        .setLabel('Unica')
        .setValue(InvoiceType.Unica),

];
