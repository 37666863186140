import {CtWebapiService, DataRequest, MethodEnum} from "@ctsolution/ct-webapi";
import {Injectable} from "@angular/core";
import {CTDateAdapter, SnackbarService} from "@ctsolution/ct-framework";

const CONTROLLER: string[] = ["dashboard"];
const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController(CONTROLLER);

@Injectable()
export class DashboardController {

    constructor(private _webapi: CtWebapiService, private snackbar: SnackbarService) {
    }

    invoiceNotification = (now: Date | null) => this.sendNotification('InvoiceNotification', now);

    warrantyNotification = (now: Date | null = null) => this.sendNotification('WarrantyNotification', now);

    private sendNotification(action: string, now: Date | null) {

        const qp: any = {hideSpinner: true};

        if (now) {

            qp['now'] = CTDateAdapter.create().transformDateToCurrentTimezone(now).toISOString();

        }

        const request: DataRequest = CONTROLLER_REQUEST()
            .setAction(action)
            .setMethod(MethodEnum.GET)
            .setQueryParams(qp)

        this._webapi
            .request(request)
            .subscribe(() => this.snackbar.open('CT_MESSAGES.success_operation'));

    }

}
