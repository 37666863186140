import {CTFieldInfos} from "@ctsolution/ct-framework";
import {environment} from "../../../../../../environments/environment";

export const CUSTOMER_CONTROL: CTFieldInfos = CTFieldInfos
    .create({
        Field: "Customer",
        ControlType: "Lookup",
        LookupInfos: {
            RemoteEndpoint: `${environment.dev.REST_API_SERVER}/Customer/CustomerLookup`,
        },
    } as CTFieldInfos)
