import { Component } from '@angular/core';
import { CtControlConfiguration, MaterialControlIcons, MaterialControlOptions } from "@ctsolution/ct-framework";
import { Currency } from "../../../../../core/enum/currency";
import { TOTAL_CONTROL } from "./total.control";

@Component({
    selector: 'app-total-control',
    template: `
        <ct-control [configuration]="control"></ct-control>`
})
export class TotalControlComponent {

    currency = Currency;
    control: CtControlConfiguration = CtControlConfiguration.create(TOTAL_CONTROL);

    ngOnInit() { }

    setTotalSuffixIcon(value: Currency) {

        if (!this.control.materialOptions) this.control.setMaterialOptions(MaterialControlOptions.create());

        if (!this.control.materialOptions?.icons) this.control?.materialOptions?.setIcons(MaterialControlIcons.create())

        // this.control
        //     .materialOptions
        //     ?.icons
        //     ?.setSuffixIcon(value === this.currency.EUR ? 'euro' : 'currency_franc');

    }

}
