import { Component, OnInit } from '@angular/core';
import { CtDatatableActionConfiguration, CtModelConfiguration, CtModelOperator } from '@ctsolution/ct-framework';
import { HISTORY_ROUTEDATA } from '../history.module';
import { historyService } from '../history.service';
import { DEFAULT_DATATABLE_CONFIGURATION } from 'src/app/core/constants';

@Component({
  selector: 'app-history-list',
  template: ` <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`,
})
export class HistoryListComponent implements OnInit {


  historyCTA: CtDatatableActionConfiguration = new CtDatatableActionConfiguration()
  .setColor("primary")
  .setIcon("info")
  .setDynamicClass(value => !value['Json'] || value['Json'] == '' ? `no-data` : null)

  datatableConfiguration = DEFAULT_DATATABLE_CONFIGURATION()
    .setCustomDatatableAction(this.historyCTA,
      (async (event: any) => {

        if(event.Json) {
          this.historyService.openDialog(event.Json);
        }

      }))

  configuration: CtModelConfiguration<HistoryListComponent> = CtModelConfiguration
  .create<HistoryListComponent>()
  .setRouteData(HISTORY_ROUTEDATA())
  .setOperations([])
  .setCTDatatableConfiguration(this.datatableConfiguration)

  constructor(private historyService : historyService) { }

  ngOnInit() {
  }

}
