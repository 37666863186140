<mat-nav-list appAccordion>
    <mat-list-item
            appAccordionLink
            [class]="'menuitem-' + menuitem.name"
            *ngFor="let menuitem of menuItems$ | async"
            routerLinkActive="selected"
            group="{{ menuitem.state }}"
    >
        <a
                class=""
                appAccordionToggle
                *ngIf="menuitem.type === 'link'"
                (click)="handleNotify(menuitem)"
        >
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ ('CT_MENU.' + menuitem.name) | translate }}</span>
        </a>

        <a class="" appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'sub'">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ ('CT_MENU.' + menuitem.name) | translate }}</span>
            <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>

        <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'">
            <mat-list-item
                    *ngFor="let childitem of menuitem.children; let j = index; let i = childitem"
                    routerLinkActive="selected"
            >
                <a
                        *ngIf="childitem.type === 'link'"
                        class="relative"
                        routerLinkActive="selected"
                        (click)="itemSelect[i] = j"
                        (click)="handleNotify(menuitem, childitem)"
                >{{ ('CT_MENU.' + childitem.name) | translate }}</a
                >
            </mat-list-item>

        </mat-nav-list>

        <div class="separator text-muted" *ngIf="menuitem.type === 'separator'">
            <span>{{ ('CT_MENU.' + menuitem.name) | translate }}</span>
        </div>
    </mat-list-item>
</mat-nav-list>
