import {Component} from '@angular/core';
import {CtControlConfiguration, CtRangeControlOptions} from "@ctsolution/ct-framework";
import {CONTRACT_YEAR_CONTROL} from "./contract-year.control";

@Component({
    selector: 'app-contract-year-control',
    template: `
        <ct-control [configuration]="control"></ct-control>`
})
export class ContractYearControlComponent {

    control: CtControlConfiguration = CtControlConfiguration.create(CONTRACT_YEAR_CONTROL);


    ngOnInit() {

        this.setup();

    }

    setup() {

        this.control
            .setValue(new Date().getFullYear());

        (<CtRangeControlOptions>this.control.options)
            .setMin(1970)
            .setMax(9999);

    }

}
