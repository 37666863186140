import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { CtButtonConfiguration, MAT_RAISED_PRIMARY, MAT_RAISED_WARN } from '@ctsolution/ct-framework';

@Component({
  selector: 'app-contract-renew-dialog',
  templateUrl: './contract-renew-dialog.component.html',
  styleUrls: ['./contract-renew-dialog.component.scss']
})
export class ContractRenewDialogComponent {

  control: FormControl = new FormControl<Date | null>(null);

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
      .create()
      .setLabel("Annulla")
      .setAction(() => this.dialogRef.close())
      .setMatherialOptions(MAT_RAISED_WARN);

  confirmButton: CtButtonConfiguration = CtButtonConfiguration
      .create()
      .setLabel("Sostituisci")
      .setAction(() => {

          this.dialogRef.close(true)

      })
      .setMatherialOptions(MAT_RAISED_PRIMARY);

  constructor(private dialogRef: MatLegacyDialogRef<ContractRenewDialogComponent>) {
  }


  close() {

  }

}
