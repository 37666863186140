import {ContractTypeListElementDTO} from "../../../../core/controller/contract-types.controller";

export class ContractExtraFieldConfiguration {
    get actionsEnabled(): boolean {
        return this._actionsEnabled;
    }

    set actionsEnabled(value: boolean) {
        this._actionsEnabled = value;
    }

    get disabled(): boolean {
        return this._disabled;
    }

    private set disabled(value: boolean) {
        this._disabled = value;
    }

    get contractType(): ContractTypeListElementDTO | null {
        return this._contractType;
    }

    private set contractType(value: ContractTypeListElementDTO | null) {
        this._contractType = value;
    }

    private _contractType: ContractTypeListElementDTO | null = null;
    private _disabled: boolean = false;

    private _actionsEnabled: boolean = false;

    private constructor() {
    }

    public static create = (): ContractExtraFieldConfiguration => new ContractExtraFieldConfiguration();

    setContractType(value: ContractTypeListElementDTO | null): ContractExtraFieldConfiguration {

        this.contractType = value;
        return this;

    }

    setDisabled(value: boolean): ContractExtraFieldConfiguration {

        this.disabled = value;
        return this;
    }

    setActionsEnabled(value: boolean): ContractExtraFieldConfiguration {

        this.actionsEnabled = value;
        return this;
    }

}
