<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div
        class="main-container"
        [dir]="dir"
        [ngClass]="{
    minisidebar: minisidebar && !mobileQuery.matches,
    boxed: boxed,
    dark: dark
  }"
>
    <!-- ============================================================== -->
    <!-- Topbar - style you can find in header.scss -->
    <!-- ============================================================== -->
    <mat-toolbar color="primary" class="topbar relative">
        <div class="w-100 fix-width-for-horizontal">
            <!-- ============================================================== -->
            <!-- sidebar toggle -->
            <!-- ============================================================== -->

            <button mat-icon-button (click)="toggleSidebar()" class="sidebar-toggle" value="sidebarclosed">
                <mat-icon>menu</mat-icon>
            </button>
            <!-- ============================================================== -->
            <!-- Logo - style you can find in header.scss -->
            <!-- ============================================================== -->
            <div class="navbar-header">
                <a class="navbar-brand" href="/">
                    <!-- Logo icon -->
                    <b>
                        <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                        <!-- Dark Logo icon -->
                        <img src="assets/images/logo/logo.png" alt="homepage" class="dark-logo"/>
                        <!-- Light Logo icon -->
                        <img src="assets/images/logo/logo.png" alt="homepage" class="light-logo"/>
                    </b>
                    <!--End Logo icon -->
                </a>
            </div>

            <span fxFlex></span>

            <!-- ============================================================== -->
            <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
            <!-- ============================================================== -->

            <span class="user-profile-cntr">Ciao, {{ $email | async }}</span>

            <button (click)="logout()" mat-icon-button>
                <mat-icon>logout</mat-icon>
            </button>

        </div>

    </mat-toolbar>
    <!-- ============================================================== -->
    <!-- End Topbar - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-container
            class="example-sidenav-container"
            [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
            [ngClass]="{ minisidebar: minisidebar }"
    >
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <mat-sidenav
            #snav
            id="snav"
            class="pl-xs"
            [mode]="mobileQuery.matches ? 'side' : 'over'"
            fixedTopGap="0"
            [opened]="mobileQuery.matches"
            [disableClose]="mobileQuery.matches"
            (opened)="sidebarOpened = true"
            (closed)="sidebarOpened = false">
            <app-vertical-sidebar class="app-sidebar" [showClass]="status" (notify)="snav.toggle()">
            </app-vertical-sidebar>
        </mat-sidenav>

        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <mat-sidenav-content class="page-wrapper" [ngClass]="status ? 'minitoggle' : ''">
            <app-breadcrumb></app-breadcrumb>
            <div class="page-container">
                <div class="page-content">
                    <router-outlet>
                        <app-spinner></app-spinner>
                    </router-outlet>
                </div>
            </div>

        </mat-sidenav-content>
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
    </mat-sidenav-container>
</div>
