import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {CustomerEditComponent} from "./customer-edit/customer-edit.component";
import {CustomerListComponent} from "./customer-list/customer-list.component";
import {CtModelRouteData} from "@ctsolution/ct-framework";
import {CustomerListModule} from "./customer-list/customer-list.module";
import {CustomerEditModule} from "./customer-edit/customer-edit.module";

export const CUSTOMER_ROUTEDATA = (): CtModelRouteData => CtModelRouteData
    .create()
    .setController("customer");

export const LOCATION_ROUTEDATA = (): CtModelRouteData => CtModelRouteData
    .create()
    .setController("location");


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        CustomerListModule,
        CustomerEditModule,
        RouterModule.forChild([
            {
                path: "",
                children: [
                    {
                        path: "",
                        redirectTo: "list",
                        pathMatch: "full"
                    },
                    {
                        path: "create",
                        component: CustomerEditComponent
                    },
                    {
                        path: "edit/:id",
                        component: CustomerEditComponent
                    },
                    {
                        path: "list",
                        component: CustomerListComponent
                    }
                ]
            }
        ])
    ]
})
export class CustomerModule {
}
