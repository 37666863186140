import { CtControlConfiguration, CtControlTypes, CtThemeTypes } from "@ctsolution/ct-framework";

export const CURRENCY_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
    name: "Currency",
    label: "Currency",
    theme: CtThemeTypes.MATERIAL,
    type: CtControlTypes.ENUMERABLE,
    validators: [
        {name: "required"}
    ]
};
