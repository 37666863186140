import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationConfirmDialogComponent} from './notification-confirm-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {NotificationConfirmDialogService} from "./notification-confirm-dialog.service";
import {CtButtonModule, CtFileUploaderModule} from "@ctsolution/ct-framework";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatLegacyDialogModule} from "@angular/material/legacy-dialog";
import {MatLegacyFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {FlexModule} from "@angular/flex-layout";


@NgModule({
    declarations: [
        NotificationConfirmDialogComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        CtFileUploaderModule,
        MatDividerModule,
        MatIconModule,
        MatDatepickerModule,
        FormsModule,
        MatInputModule,
        ReactiveFormsModule,
        CtButtonModule,
        MatLegacyDialogModule,
        MatLegacyFormFieldModule,
        FlexModule
    ],
    providers: [NotificationConfirmDialogService]
})
export class NotificationConfirmDialogModule {
}
