import {CtControlConfiguration, CtControlTypes, CtThemeTypes} from "@ctsolution/ct-framework";

export const CONTRACT_NUMBER_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
    name: "ContractNumber",
    label: "contractnumber",
    theme: CtThemeTypes.MATERIAL,
    validators: [
        {name: "required"},
        {name: "min", value: 1},
        {name: "max", value: 999},
    ],
    type: CtControlTypes.NUMBER
};
