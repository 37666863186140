import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContractAlertFieldComponent} from "./contract-alert-field.component";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {FlexModule} from "@angular/flex-layout";
import {CtControlModule} from "@ctsolution/ct-framework";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
    declarations: [
        ContractAlertFieldComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        FlexModule,
        CtControlModule,
        ReactiveFormsModule
    ],
    exports: [ContractAlertFieldComponent]
})
export class ContractAlertFieldModule {
}
