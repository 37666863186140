import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {WarrantyListComponent} from "./warranty-list/warranty-list.component";
import {WarrantyListModule} from "./warranty-list/warranty-list.module";
import {CtModelRouteData} from "@ctsolution/ct-framework";

export const WARRANTY_ROUTEDATA = (): CtModelRouteData => CtModelRouteData
    .create()
    .setController("warranty");

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        WarrantyListModule,
        RouterModule
            .forChild([
                {
                    path: "",
                    children: [
                        {
                            path: "",
                            redirectTo: "list",
                            pathMatch: "full"
                        },
                        {
                            path: "create",
                            redirectTo: "/ct-model/warranty/create",
                            pathMatch: "full"
                        },
                        {
                            path: "edit/:id",
                            redirectTo: "/ct-model/warranty/edit/:id",
                            pathMatch: "full"
                        },
                        {
                            path: "list",
                            component: WarrantyListComponent
                        }
                    ]
                }
            ])
    ]
})
export class WarrantyModule {
}
