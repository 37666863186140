import {Component} from '@angular/core';
import {CtControlConfiguration} from "@ctsolution/ct-framework";
import {VALIDITY_END_CONTROL} from "./validity-end.control";

@Component({
    selector: 'app-validity-end-control',
    template: `
        <ct-control [configuration]="control"></ct-control>`
})
export class ValidityEndControlComponent {

    control: CtControlConfiguration = CtControlConfiguration.create(VALIDITY_END_CONTROL);

    ngOnInit() {

        this.setup();

    }

    setup() {

        this.control
            .setDisabled(true);

    }

    updateEndDateControl(value: any) {

        const currentStartValue: Date | null = new Date(value.StartDate);
        const monthValue: string | null = value.MonthDuration;

        let nextValue: Date | null = new Date(currentStartValue.getTime());

        if (monthValue) {

            nextValue.setMonth(nextValue.getMonth() + (+monthValue));

        } else {

            nextValue
                .setFullYear(nextValue.getFullYear() + 1);

        }

        nextValue.setDate(nextValue.getDate() - 1); // Data Inizio + Mesi - 1 GG

        this.control
            .setValue(nextValue)
    }

}
