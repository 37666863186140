import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StateBulletComponent} from './state-bullet.component';
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
    declarations: [
        StateBulletComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MatIconModule
    ],
    exports: [StateBulletComponent]
})
export class StateBulletModule {
}
