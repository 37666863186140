import {ContractDTO} from "./contract-dto";

export class ContractFile {

    Oid: number | null = null;
    Name: string | null = null;
    CreateAt: Date | null = null;

    constructor(
        public File: File | null = null,
        public Contract: ContractDTO<any> | null = null
    ) {

        if (File) {

            this.Name = File.name;

        }

    }

}
