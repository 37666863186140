<h1 mat-dialog-title>Sostituire il contratto?</h1>

<mat-dialog-content>



</mat-dialog-content>

<mat-dialog-actions
        fxLayout="row wrap"
        fxLayoutAlign="space-between center">

    <ct-button [configuration]="cancelButton"></ct-button>

    <ct-button [configuration]="confirmButton"></ct-button>

</mat-dialog-actions>
