import { CtSelectControlValue } from "@ctsolution/ct-framework";
import { ContractState } from "../../../../../core/enum/contract-state";

export const CREATE_STATES: CtSelectControlValue[] = [
    CtSelectControlValue
        .create()
        .setLabel('Attivo')
        .setValue(ContractState.Active),
    CtSelectControlValue
        .create()
        .setLabel('In Scadenza')
        .setValue(ContractState.Expiring),
    CtSelectControlValue
        .create()
        .setLabel('Scaduto')
        .setValue(ContractState.Expired),
    CtSelectControlValue
        .create()
        .setLabel('Sostituito')
        .setValue(ContractState.Replaced)
];
