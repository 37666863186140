import { Component } from '@angular/core';
import { CtControlConfiguration, CtThemeTypes } from "@ctsolution/ct-framework";
import { ContractState } from "../../../../../core/enum/contract-state";
import { CONTRACT_STATE_CONTROL } from "./contract-state.control";
import { CREATE_STATES } from "./contract-state.control-options";

@Component({
    selector: 'app-contract-state-control',
    template: `
        <ct-control [configuration]="control"></ct-control>`
})
export class ContractStateControlComponent {

    control: CtControlConfiguration = CtControlConfiguration.create(CONTRACT_STATE_CONTROL);

    ngOnInit() {

        this.setup();

    }

    setup() {

        this.control
            .setTheme(CtThemeTypes.MATERIAL)
            .setValueOptions(CREATE_STATES)
            .setValue(ContractState.Active);
    }

    disable(newValue: ContractState) {
        if(newValue == 3){
            this.control.setDisabled(true) 
        }else{
            this.control.setDisabled(false)
        }
    }
}
