import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import { ContractRenewDialogComponent } from './contract-renew-dialog.component';

@Injectable()
export class ContractRenewDialogService {

    constructor(private dialog: MatDialog) {
    }

    open = () => this.dialog.open(ContractRenewDialogComponent, { maxWidth: '500px'})

}
