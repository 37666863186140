<div [class]="disableForm ? 'dynamic-fields-cntr disabled' : 'dynamic-fields-cntr'"
     [hidden]="!specificContractConfiguration?.contractType">
    <div [class]="disableForm ? 'dynamic-fields-cntr-content disabled' : 'dynamic-fields-cntr-content'">
        <mat-card [class]="formCustomError ? 'error':''">

            <mat-card-content>

                <mat-card-title>

                    {{ specificContractConfiguration?.contractType?.Description ?? 'Campi aggiuntivi' }}

                </mat-card-title>

                <ct-form-container
                    #FormContainer
                    *ngIf="modelConfiguration"
                    [configuration]="modelConfiguration"></ct-form-container>

            </mat-card-content>

            <mat-card-actions *ngIf="specificContractConfiguration?.actionsEnabled">

                <ct-button [configuration]="cancelButton"></ct-button>

            </mat-card-actions>

            <span class="errorMessage" *ngIf="formCustomError">*{{formCustomErrorMessage}}</span>

        </mat-card>
    </div>
</div>
