<form class="contract-create-cntr" [formGroup]="form">

    <div fxLayout="row wrap">

        <div
                [fxFlex]="40"
                [fxFlex.xs]="100"
                [fxFlex.sm]="100">

            <app-contract-info-field
                #ContractInfoField
                (setupContractTypeExtraField)="setupContractTypeExtraField($event)"></app-contract-info-field>

            <app-contract-renew-field
                    [hidden]="!ContractDetailField.form.value.Oid"
                    (onRenew)="onSubmit(true)"
                    #ContractRenewField></app-contract-renew-field>

            <app-contract-alert-field
                    [hidden]="!form.get('Monitoring')"
                    #ContractMonitoringField></app-contract-alert-field>

        </div>

        <div
                [fxFlex]="60"
                [fxFlex.xs]="100"
                [fxFlex.sm]="100">

            <app-contract-detail-field #ContractDetailField></app-contract-detail-field>

        </div>

    </div>

    <div>

        <app-contract-extra-field #ContractExtraField (updateAmount)="updateAmount($event)"></app-contract-extra-field>

    </div>

    <div class="form-actions">

        <ct-button [configuration]="cancelButton"></ct-button>

        <ct-button *ngIf="!this.viewModel.isTech" [configuration]="submitButton"></ct-button>

    </div>

</form>
