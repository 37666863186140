import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryListComponent } from './history-list.component';
import { CtModelModule } from '@ctsolution/ct-framework';
import { historyService } from '../history.service';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule
  ],
  declarations: [HistoryListComponent],
  exports : [HistoryListComponent],
  providers : [historyService]
})
export class HistoryListModule { }
