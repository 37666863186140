import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContractDetailFieldComponent} from "./contract-detail-field.component";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {FlexModule} from "@angular/flex-layout";
import {CtControlModule} from "@ctsolution/ct-framework";
import {ReactiveFormsModule} from "@angular/forms";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {ValidityStartControlComponent} from './validity-start-control/validity-start-control.component';
import {ValidityEndControlComponent} from './validity-end-control/validity-end-control.component';
import {OrderDateControlComponent} from './order-date-control/order-date-control.component';
import {OrderNumberControlComponent} from './order-number-control/order-number-control.component';
import {ContractNumberControlComponent} from './contract-number-control/contract-number-control.component';
import {ContractYearControlComponent} from './contract-year-control/contract-year-control.component';
import {AutomaticRenewControlComponent} from './automatic-renew-control/automatic-renew-control.component';
import {ContractStateControlComponent} from './contract-state-control/contract-state-control.component';
import {MonthDurationControlComponent} from './month-duration-control/month-duration-control.component';
import {TotalControlComponent} from './total-control/total-control.component';
import {InvoiceTypeControlComponent} from './invoice-type-control/invoice-type-control.component';
import {CurrencyControlComponent} from "./currency-control/currency-control.component";
import { CustomerControlComponent } from './customer-control/customer-control.component';
import { SupplierControlComponent } from './supplier-control/supplier-control.component';
import { CommercialControlComponent } from './commercial-control/commercial-control.component';
import { CustomerLocationControlComponent } from './customer-location-control/customer-location-control.component';

@NgModule({
    declarations: [
        ContractDetailFieldComponent,
        ValidityStartControlComponent,
        ValidityEndControlComponent,
        OrderDateControlComponent,
        OrderNumberControlComponent,
        ContractNumberControlComponent,
        ContractYearControlComponent,
        AutomaticRenewControlComponent,
        ContractStateControlComponent,
        MonthDurationControlComponent,
        TotalControlComponent,
        CurrencyControlComponent,
        InvoiceTypeControlComponent,
        CustomerControlComponent,
        SupplierControlComponent,
        CommercialControlComponent,
        CustomerLocationControlComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        FlexModule,
        CtControlModule,
        ReactiveFormsModule,
        MatListModule
    ],
    exports: [
        ContractDetailFieldComponent
    ]
})
export class ContractDetailFieldModule {
}
