import { Routes } from "@angular/router";

import { ErrorComponent } from "./error/error.component";
import { ForgotComponent } from "./forgot/forgot.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";

export const AuthenticationRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "404",
        component: ErrorComponent
      },
      {
        path: "forgot",
        component: ForgotComponent
      },
      {
        path: "login",
        component: LoginComponent
      },
      {
        path: "register",
        component: RegisterComponent
      },
      {
        path: "",
        pathMatch: "full",
        redirectTo: "login"
      }
    ]
  }
];
