import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'show-hide-password-toggle',
  templateUrl: './show-hide-password-toggle.component.html',
  styleUrls: ['./show-hide-password-toggle.component.scss']
})
export class ShowHidePasswordToggleComponent {

  public isVisible: boolean = false;
  @Output() onChange  = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }


  toggleVisibility(): void {
    this.isVisible = !this.isVisible;
    this.onChange.emit(this.isVisible);
  }
  


}
