import { CTBase } from "@ctsolution/ct-base";
import { Currency } from './../enum/currency';

export class ContractDTO<T> extends CTBase<ContractDTO<T>> {

    Oid: number | null = null;
    ContractCode: string | null = null;
    StartDate: Date | null = null;
    EndDate: Date | null = null;
    ContractNumber: number | null = null;
    ContractYear: number | null = null;
    AutomaticRenew: boolean | null = null;
    State: number | null = null;
    CustomerCompanyName: string | null = null;
    Monitoring: boolean | null = null;
    AlertManagement: boolean | null = null;
    AlertEmail: string | null = null;
    SpecificContract: T | null = null;
    ContractType: { Oid: number; Name?: string; } | null = null;
    Customer: { Oid: number } | number | null = null;
    Supplier: { Oid: number } | number | null = null;
    User: { CommercialCode: string } | string | null = null;
    ParentContractContractCode: string | null = null;
    ChildContractContractCode: string | null = null;
    CustomerLocation: string | null = null;
    Total: number | null = null;
    InvoiceCurrency: string | null = null;
    Currency: Currency | null = null;
    MonthDuration: number | null = null;

    private constructor();
    private constructor(model?: ContractDTO<T>);
    private constructor(model?: ContractDTO<T>) {

        super();

        if (model) {

            this.getClass(model);

        }

    }

    public static create = <T>(model?: ContractDTO<T>): ContractDTO<T> => new ContractDTO(model);

    setMonitoring(value: boolean | null): ContractDTO<T> {

        this.Monitoring = value;
        return this;

    }

    setAlertManagement(value: boolean | null): ContractDTO<T> {

        this.AlertManagement = value;
        return this;

    }

    setAlertEmail(value: string | null): ContractDTO<T> {

        this.AlertEmail = value;
        return this;

    }

    setSpecificContract(value: T | null): ContractDTO<T> {

        this.SpecificContract = value;
        return this;

    }

    setContractType(Oid: number): ContractDTO<T> {

        this.ContractType = Oid ? {Oid} : null;
        return this;

    }

    setCustomer(Oid: number): ContractDTO<T> {

        this.Customer = Oid ? {Oid} : null;
        return this;

    }

    setSupplier(Oid: number): ContractDTO<T> {

        this.Supplier = Oid ? {Oid} : null;
        return this;

    }

    setUser(CommercialCode: string): ContractDTO<T> {

        this.User = CommercialCode ? {CommercialCode} : null;
        return this;

    }


}
