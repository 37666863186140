import {Component} from '@angular/core';
import {CtControlConfiguration, CtRangeControlOptions} from "@ctsolution/ct-framework";
import {CONTRACT_NUMBER_CONTROL} from "./contract-number.control";

@Component({
    selector: 'app-contract-number-control',
    template: `
        <ct-control [configuration]="control"></ct-control>`
})
export class ContractNumberControlComponent {

    control: CtControlConfiguration = CtControlConfiguration.create(CONTRACT_NUMBER_CONTROL);

    ngOnInit() {

        this.setup();

    }

    setup() {

        (<CtRangeControlOptions>this.control.options)
            .setMin(1)
            .setMax(999);

    }

}
