<h1 mat-dialog-title>Riepilogo Modifiche</h1>
<div mat-dialog-content>
  <ul>
    <li *ngFor="let item of formattedData">
      <strong>{{ item.label | translate }}:</strong>
      <br />
      <span [innerHTML]="item.content"></span>
    </li>
  </ul>
</div>
<div mat-dialog-actions>
<ct-button [configuration]="cancelButton"></ct-button>
</div>