import {Injectable} from "@angular/core";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest, MethodEnum} from "@ctsolution/ct-webapi";
import {CtModelDatatableOperators, CtSortOrderDescriptorParameter} from "@ctsolution/ct-framework";
import {CTBase} from "@ctsolution/ct-base";

const controller: string[] = ['contracttype'];
const DEFAULT_PARAMETER: DataRequest = DataRequest.create().setController(controller);

const DEFAULT_PAGINATION_PARAMETER: CtModelDatatableOperators = CtModelDatatableOperators.create();

DEFAULT_PAGINATION_PARAMETER
    .Pagination
    ?.setElementsForPage(100)
    ?.setSortOrders([CtSortOrderDescriptorParameter.create('Code')]);

@Injectable()
export class ContractTypesController {

    constructor(private _webapi: CtWebapiService) {
    }

    getContractTypeLookup(): Promise<Array<ContractTypeListElementDTO>> {

        return new Promise<Array<ContractTypeListElementDTO>>((resolve) => {

            const request: DataRequest = DEFAULT_PARAMETER
                .setAction('GetContractTypeLookup')
                .setBody(DEFAULT_PAGINATION_PARAMETER)
                .setMethod(MethodEnum.GET)
                .setQueryParams({
                    hideSpinner: true
                })

            this._webapi
                .get<CtWebapiGenericResponse<Array<ContractTypeListElementDTO>>>(request)
                .subscribe({
                    next: response => resolve(response.Result)
                })

        })

    }

}

export class ContractTypeListElementDTO {

    HasAlertAndMonitoring: boolean | null = null;
    Code: string | null = null;
    Description: string | null = null;
    AutoIncrementalAmount: boolean | null = null;

}

export class ContractTypeDTO extends CTBase<ContractTypeDTO> {

    Oid: number | null = null;
    ExtraFieldObject1: DynamicFieldsDTO | null = null;
    ExtraFieldObject2: DynamicFieldsDTO | null = null;
    ExtraFieldObject3: DynamicFieldsDTO | null = null;
    ExtraFieldObject4: DynamicFieldsDTO | null = null;

    constructor();
    constructor(model?: ContractTypeDTO);
    constructor(model?: ContractTypeDTO) {

        super();

        if (model) {

            this.getClass(model);

        }

    }

}

export class DynamicFieldsDTO {

    Label: string | null = null;
    DataSource: string[] = [];

    constructor() {
    }

    setLabel(value: string): DynamicFieldsDTO {

        this.Label = value;
        return this;

    }

    setDataSource(value: string[]): DynamicFieldsDTO {

        this.DataSource = value;
        return this;

    }

}
