import {Injectable} from "@angular/core";
import {CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";
import {CtThemeMenuConfiguration} from "./_classes/ct-theme-menu.configuration";
import {Menu} from "./_classes/menu-item.interface";

@Injectable()
export class MenuItems {

    constructor(private _webapi: CtWebapiService) {
    }

    getMenuitem = (): Promise<Menu[]> => new Promise<Menu[]>((resolve) => {

        const request: DataRequest = new DataRequest(window.location.origin + `/assets/json/user.menu.json`);

        this._webapi
            .get(request)
            .subscribe((result: CtThemeMenuConfiguration) => {

                const configuration: CtThemeMenuConfiguration = CtThemeMenuConfiguration.create(result);
                resolve(configuration.items);

            });

    });

}
