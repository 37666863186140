import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CtModelModule, CtModelRouteData } from '@ctsolution/ct-framework';
import { ReportComponent } from './report.component';

export const REPORT_ROUTEDATA = (): CtModelRouteData => CtModelRouteData
    .create()
    .setController("report");

@NgModule({
  imports: [
    CommonModule,
    CtModelModule,
    RouterModule
      .forChild([
        {
          path: "",
          children: [
            {
              path: "",
              component: ReportComponent
            }
          ]
        }
      ]),
      
  ],
  declarations: [ReportComponent]
})
export class ReportModule { }
