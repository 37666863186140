import {Component} from '@angular/core';
import {CtControlConfiguration} from "@ctsolution/ct-framework";
import {MONTH_DURATION_CONTROL} from "./month-duration.control";

@Component({
    selector: 'app-month-duration-control',
    template: `
        <ct-control [configuration]="control"></ct-control>`
})
export class MonthDurationControlComponent {

    control: CtControlConfiguration = CtControlConfiguration.create(MONTH_DURATION_CONTROL);

    ngOnInit() {

        this.setup();

    }

    setup() {

    }

}
