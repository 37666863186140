import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import { HistoryDialogComponent } from './history-dialog/history-dialog.component';

@Injectable()
export class historyService {

    constructor(private dialog: MatDialog) {
    }

    openDialog = (value : string) => this.dialog.open(HistoryDialogComponent, {data: value, maxWidth: '600px'})

}