import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryDialogComponent } from './history-dialog.component';
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import { MatListModule } from '@angular/material/list';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CtButtonModule } from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatListModule,
    TranslateModule,
    CtButtonModule
  ],
  declarations: [HistoryDialogComponent],
  exports: [HistoryDialogComponent],
  providers: [TranslateService]
})
export class HistoryDialogModule { }
