import { AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import {
    CtBinaryOperator,
    CtDatatableActionConfiguration,
    CtDatatableComponent,
    CtDatatableConfiguration,
    CtModelConfiguration,
    CTModelDatatableFilter,
    CtModelDatatableOperators,
    CtModelOperator,
    CtSelectControlOptions,
    CtSortOrderDescriptorParameter
} from "@ctsolution/ct-framework";
import { StateBulletConfiguration } from "../../components/state-legend/state-bullet/state-bullet.configuration";
import { StateLegendConfiguration } from "../../components/state-legend/state-legend.configuration";
import { ContractDTO } from "../../core/class/contract-dto";
import { DEFAULT_DATATABLE_CONFIGURATION } from "../../core/constants";
import { ContractState } from "../../core/enum/contract-state";
import { Currency } from "../../core/enum/currency";
import { EnumHelper } from "../../core/lib/enum.helper";
import { ContractAttachmentsService } from "./contract-attachments.service";
import { CONTRACT_ROUTEDATA } from "./contract.module";
import { SpecificContractDetailService } from "./specific-contract-detail/specific-contract-detail.service";

@Component({
    selector: 'app-contract',
    templateUrl: './contract.component.html',
    styleUrls: ['./contract.component.scss']
})
export class ContractComponent<T> implements AfterViewInit {

    @ViewChild('legendTemplate') legendTemplate: TemplateRef<any> | null = null;
    @ViewChild('StateCellTemplate') stateCellTemplate: TemplateRef<any> | null = null;
    @ViewChild('AmountCellTemplate') amountCellTemplate: TemplateRef<any> | null = null;
    @ViewChild('AmountPerYearCellTemplate') amountPerYearCellTemplate: TemplateRef<any> | null = null;

    fileHandlingCTA: CtDatatableActionConfiguration = new CtDatatableActionConfiguration()
        .setColor("primary")
        .setIcon("attachment")
        .setDynamicClass(value => !value['FilesAttachedCount'] ? `no-data` : null)

    datatableConfiguration: CtDatatableConfiguration = DEFAULT_DATATABLE_CONFIGURATION()
        .setCustomDatatableAction(this.fileHandlingCTA,
            (async (event: ContractDTO<T>) => {

                const ref = await this.contractAttachmentsService.open(event)
                ref.disableClose = true
                ref
                    .afterClosed()
                    .subscribe(result => (event as any)['FilesAttachedCount'] = result ?? 0)

            }));

    configuration: CtModelConfiguration<ContractComponent<T>> | null = null;

    constructor(
        private router: Router,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        public enumHelper: EnumHelper,
        private contractAttachmentsService: ContractAttachmentsService,
        private specificContractDetailService: SpecificContractDetailService) {
    }

    ngAfterViewInit() {

        const configuration: CtModelConfiguration<ContractComponent<T>> = CtModelConfiguration
            .create<ContractComponent<T>>()
            .setRouteData(CONTRACT_ROUTEDATA())
            .setOperations([CtModelOperator.Detail])
            .setOnCreate(() => this.router.navigate(["create"], {relativeTo: this.route}))
            .setOnEdit((event: ContractDTO<T>) => this.router.navigate(["edit", event.Oid!.toString()], {relativeTo: this.route}))
            .setOnDetail((event: ContractDTO<T>) => this.specificContractDetailService.open(event))
            .setFieldsCustomTemplates([
                {
                    prop: 'State',
                    cellTemplate: this.stateCellTemplate,
                    filterOptions: {
                        multiple: true
                    } as any
                },
                {
                    prop: 'Total',
                    cellTemplate: this.amountCellTemplate
                },
                {
                    prop: 'TotalPerYear',
                    cellTemplate: this.amountPerYearCellTemplate
                },
                {
                    prop: 'Currency',
                    visible: false
                },
                {
                    prop: 'InvoiceCurrency',
                    visible: false
                }
            ])
            .setOrderValues(
                new Array<CtSortOrderDescriptorParameter>(
                    CtSortOrderDescriptorParameter
                        .create('Customer.CompanyName'),
                    CtSortOrderDescriptorParameter
                        .create('Total'),
                    CtSortOrderDescriptorParameter
                        .create('MonthDuration')
                ))
            .setCTDatatableConfiguration(this.datatableConfiguration);

        if (this.legendTemplate) {

            configuration.setLegendTemplate(this.legendTemplate);

        }

        this.configuration = configuration;

        setTimeout(() => {
            this.configuration?.CTDatatableConfiguration?.columns.find(c => c.prop == "Customer")?.control?.control?.valueChanges.subscribe((value : any) => {

                if(value.length > 0)
                {

                    let options : CtSelectControlOptions = this.configuration?.CTDatatableConfiguration?.columns.find(c => c.prop == "CustomerLocation")?.control?.options as CtSelectControlOptions
                    if(!options) options = CtSelectControlOptions.create();

                    const filters : CTModelDatatableFilter[] = [];

                    value.forEach((element : any) => {
                        
                        filters.push(CTModelDatatableFilter
                        .create()
                        .setField('[Customer.Oid] == ?')
                        .setValue([element])
                        .setOperatorType(CtBinaryOperator.OrOperator));

                    });

                    options.setLookupFilter(!value? null : (() => CtModelDatatableOperators
                    .create()
                    .setFilters(filters)));

                    options.notifyValuesUpdated();
                    this.configuration?.CTDatatableConfiguration?.columns.find(c => c.prop == "CustomerLocation")?.control?.setOptions(options)

                }
                else
                {
                    let options : CtSelectControlOptions = this.configuration?.CTDatatableConfiguration?.columns.find(c => c.prop == "CustomerLocation")?.control?.options as CtSelectControlOptions
                    if(!options) options = CtSelectControlOptions.create();
                    options.setLookupFilter((() => CtModelDatatableOperators
                    .create()
                    .setFilters([])));
                    options.notifyValuesUpdated();
                    this.configuration?.CTDatatableConfiguration?.columns.find(c => c.prop == "CustomerLocation")?.control?.setOptions(options)

                }
            
            })

            if(this.configuration?.CTDatatableConfiguration?.columns.find(c => c.prop == "Customer")?.control?.control?.value)
            {
                
                const value = this.configuration?.CTDatatableConfiguration?.columns.find(c => c.prop == "Customer")?.control?.control?.value
                if(value.length > 0)
                {
    
                        let options : CtSelectControlOptions = this.configuration?.CTDatatableConfiguration?.columns.find(c => c.prop == "CustomerLocation")?.control?.options as CtSelectControlOptions
                        if(!options) options = CtSelectControlOptions.create();
    
                        const filters : CTModelDatatableFilter[] = [];
    
                        value.forEach((element : any) => {
                            
                            filters.push(CTModelDatatableFilter
                            .create()
                            .setField('[Customer.Oid] == ?')
                            .setValue([element])
                            .setOperatorType(CtBinaryOperator.OrOperator));
    
                        });
    
                        options.setLookupFilter(!value? null : (() => CtModelDatatableOperators
                        .create()
                        .setFilters(filters)));
    
                        options.notifyValuesUpdated();
                        this.configuration?.CTDatatableConfiguration?.columns.find(c => c.prop == "CustomerLocation")?.control?.setOptions(options)
    
                }
            }

        }, 1000);

        this.cdr.detectChanges();

    }

    protected readonly Currency = Currency;

    getRowCurrencyValue(row: any) {

        return row.InvoiceCurrency === 'EUR' ? 'EUR' : 'CHF';

    }

    stateLabelPrefix = 'CONTRACT_STATE.';

    stateBulletConfiguration = (state: ContractState | string) => StateBulletConfiguration
        .create(
            this.enumHelper.getEnumLabel(ContractState, state))
        .setLabelPrefix(this.stateLabelPrefix)

    stateLegendConfiguration = () => StateLegendConfiguration
        .create(ContractState)
        .setLabelPrefix(this.stateLabelPrefix);

}
