import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {SpecificContractDetailComponent} from "./specific-contract-detail.component";
import {ContractDTO} from "../../../core/class/contract-dto";

@Injectable()
export class SpecificContractDetailService {
    constructor(private dialog: MatDialog) {
    }

    open = <T>(contract: ContractDTO<T>): MatDialogRef<SpecificContractDetailComponent<T>> => this.dialog.open((SpecificContractDetailComponent<T>), {
        data: contract,
        panelClass: 'specific-contract-dialog-cntr',
        minWidth: '500px',
        maxHeight: '95vh'
    })

}
