import {Component, Input} from '@angular/core';
import {StateLegendConfiguration} from "./state-legend.configuration";
import {StateBulletConfiguration} from "./state-bullet/state-bullet.configuration";

@Component({
    selector: 'app-state-legend',
    templateUrl: './state-legend.component.html',
    styleUrls: ['./state-legend.component.scss']
})
export class StateLegendComponent<T> {

    @Input() configuration: StateLegendConfiguration<T> | null = null;
    states: string[] = [];

    constructor() {
    }

    ngOnInit() {

        if (!this.configuration?.values) return;

        this.states = Object.keys(this.configuration.values).filter((v) => isNaN(Number(v)))

    }

    bulletStateConfiguration = (state: T) => StateBulletConfiguration.create(<string>state).setLabelPrefix(this.configuration?.labelPrefix ?? '');

}
