export class StateLegendConfiguration<T> {

    labelPrefix: string | null = null;

    private constructor(public values: T) {
    }

    public static create = <T>(values: T) => new StateLegendConfiguration(values);

    setLabelPrefix(value: string | null) {

        this.labelPrefix = value;
        return this;

    }

}
