import {CtControlConfiguration, CtControlTypes, CtThemeTypes} from "@ctsolution/ct-framework";

export const CONTRACT_YEAR_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
    name: "ContractYear",
    label: "contractyear",
    theme: CtThemeTypes.MATERIAL,
    type: CtControlTypes.NUMBER,
    validators: [
        {name: "required"},
        {name: "min", value: 1970},
        {name: "max", value: 9999},
    ],
};
