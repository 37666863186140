import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContractCreateComponent} from "./contract-create.component";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {TranslateModule} from "@ngx-translate/core";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {ReactiveFormsModule} from "@angular/forms";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {CtButtonModule, CtControlModule} from "@ctsolution/ct-framework";
import {ContractAlertFieldModule} from "./contract-alert-field/contract-alert-field.module";
import {ContractDetailFieldModule} from "./contract-detail-field/contract-detail-field.module";
import {ContractInfoFieldModule} from "./contract-info-field/contract-info-field.module";
import {ContractExtraFieldModule} from "./contract-extra-field/contract-extra-field.module";
import {ContractRenewFieldModule} from "./contract-renew-field/contract-renew-field.module";
import { ContractRenewDialogModule } from './contract-renew-dialog/contract-renew-dialog.module';
import { ContractRenewDialogService } from './contract-renew-dialog/contract-renew-dialog.service';

@NgModule({
    declarations: [
        ContractCreateComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        CtButtonModule,
        TranslateModule,
        CtControlModule,
        FlexModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatCardModule,
        ContractAlertFieldModule,
        ContractDetailFieldModule,
        ContractInfoFieldModule,
        ContractExtraFieldModule,
        ContractRenewFieldModule,
        ContractRenewDialogModule
    ],
    exports: [ContractCreateComponent],
    providers:[ContractRenewDialogService]
})
export class ContractCreateModule {
}
