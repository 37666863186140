<mat-card class="contract-detail-cntr">

    <mat-card-content [formGroup]="form">

        <mat-card-title>

            Dettagli Contratto

        </mat-card-title>

        <div [fxLayout]="'row wrap'">

            <div class="control-cntr"
                 [fxFlex]="50"
                 [fxFlex.sm]="100"
                 [fxFlex.xs]="100">

                <app-contract-number-control></app-contract-number-control>

            </div>

            <div class="control-cntr"
                 [fxFlex]="50"
                 [fxFlex.sm]="100"
                 [fxFlex.xs]="100">

                <app-contract-year-control></app-contract-year-control>

            </div>

            <div class="control-cntr checkbox-cntr"
                 [fxFlex]="50"
                 [fxFlexOffset.gt-lg]="50"
                 [fxFlexOffset.gt-md]="50"
                 [fxFlex.sm]="100"
                 [fxFlex.xs]="100">

                <app-automatic-renew-control></app-automatic-renew-control>

            </div>


            <div class="control-cntr"
                 [fxFlex]="50"
                 [fxFlex.sm]="100"
                 [fxFlex.xs]="100">

                <app-contract-state-control></app-contract-state-control>

            </div>

            <div class="control-cntr"
                 [fxFlex]="50"
                 [fxFlex.sm]="100"
                 [fxFlex.xs]="100">

                <app-total-control></app-total-control>

            </div>

            <div class="control-cntr"
                 [fxFlex]="50"
                 [fxFlex.sm]="100"
                 [fxFlex.xs]="100">

                <app-currency-control></app-currency-control>

            </div>

            <div class="control-cntr"
                 [fxFlex]="50"
                 [fxFlex.sm]="100"
                 [fxFlex.xs]="100">

                <app-invoice-type-control></app-invoice-type-control>

            </div>

        </div>

        <mat-divider></mat-divider>

        <mat-card-title>

            Cliente e Commerciale di Riferimento

        </mat-card-title>

        <div fxLayout="row wrap">

            <div class="control-cntr"
                 [fxFlex]="50"
                 [fxFlex.sm]="100"
                 [fxFlex.xs]="100">

                <app-customer-control></app-customer-control>

            </div>

            <div class="control-cntr"
                 [fxFlex]="50"
                 [fxFlex.sm]="100"
                 [fxFlex.xs]="100">

                <app-commercial-control></app-commercial-control>

            </div>

            <div class="control-cntr"
                 [fxFlex]="50"
                 [fxFlex.sm]="100"
                 [fxFlex.xs]="100">

                <app-customer-location-control></app-customer-location-control>

            </div>

            <div class="control-cntr"
                 [fxFlex]="50"
                 [fxFlex.sm]="100"
                 [fxFlex.xs]="100">

                <app-supplier-control></app-supplier-control>

            </div>

            <div class="control-cntr"
                 [fxFlex]="50"
                 [fxFlex.sm]="100"
                 [fxFlex.xs]="100">

                <app-order-number-control></app-order-number-control>

            </div>

            <div class="control-cntr"
                 [fxFlex]="50"
                 [fxFlex.sm]="100"
                 [fxFlex.xs]="100">

                <app-order-date-control></app-order-date-control>

            </div>

        </div>

        <mat-divider></mat-divider>

        <mat-card-title>

            Validità Contratto

        </mat-card-title>

        <div fxLayout="row wrap">


            <div class="control-cntr"
                 [fxFlex]="50"
                 [fxFlex.sm]="100"
                 [fxFlex.xs]="100">

                <app-validity-start-control></app-validity-start-control>

            </div>

            <div class="control-cntr"
                 [fxFlex]="50"
                 [fxFlex.sm]="100"
                 [fxFlex.xs]="100">

                <app-month-duration-control></app-month-duration-control>

            </div>

            <div class="control-cntr"
                 [fxFlex]="50"
                 [fxFlex.sm]="100"
                 [fxFlex.xs]="100">

                <app-validity-end-control></app-validity-end-control>

            </div>

        </div>

    </mat-card-content>

</mat-card>
