import {CtDatatableConfiguration} from "@ctsolution/ct-framework";

export const DEFAULT_DATATABLE_CONFIGURATION = (): CtDatatableConfiguration => CtDatatableConfiguration.create()
    .setScrollbarH(true)
    //.setScrollbarV(true)
    .setPageLimitChangeEnabled(true)
    .setLimit(100)
    .setRowHeight(60)
    .setHeaderHeight(60)
    .setFiltersEnabled(true)
    .setRowHeight(35)
    .setPageLimitChangeOptions([10, 20, 40, 60, 80, 100]);
