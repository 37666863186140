import {Component, Input} from '@angular/core';
import {StateBulletConfiguration} from "./state-bullet.configuration";

@Component({
    selector: 'app-state-bullet',
    templateUrl: './state-bullet.component.html',
    styleUrls: ['./state-bullet.component.scss']
})
export class StateBulletComponent {

    @Input() configuration: StateBulletConfiguration | null = null;

}
