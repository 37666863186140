import {Component} from '@angular/core';
import {CtControlConfiguration} from "@ctsolution/ct-framework";
import {INVOICE_TYPE_CONTROL} from "./invoice-type.control";
import {CONTRACT_INVOICE_TYPES} from "./contract-invoice-type.control-options";

@Component({
    selector: 'app-invoice-type-control',
    template: `
        <ct-control [configuration]="control"></ct-control>`
})
export class InvoiceTypeControlComponent {

    control: CtControlConfiguration = CtControlConfiguration.create(INVOICE_TYPE_CONTROL);

    ngOnInit() {

        this.setup();

    }

    setup() {

        this.control
            .setValueOptions(CONTRACT_INVOICE_TYPES);

    }

}
