import {Injectable} from "@angular/core";
import {CtModelConfiguration, CTModelDatatableFilter} from "@ctsolution/ct-framework";
import {ContractDTO} from "../class/contract-dto";
import {ControllerHelper} from "./controller.helper";
import {CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";

const CONTROLLER: string[] = ["contract"];
const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController(CONTROLLER);

@Injectable()
export class ContractController {

    constructor(
        private _helper: ControllerHelper,
        private _webapi: CtWebapiService) {
    }

    get = <T>(filters: CTModelDatatableFilter[]): Promise<CtModelConfiguration<Array<ContractDTO<T>>>> => this._helper.list(CONTROLLER_REQUEST()
        .setBody({
            Filters: filters
        }));

    renewContract(parameter: ContractDTO<any>) {

        const request: DataRequest = CONTROLLER_REQUEST()
            .setAction('RenewContract')
            .setBody(parameter);

        return this._webapi
            .post(request)

    }

}
