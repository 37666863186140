import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import { CtButtonConfiguration, MAT_RAISED_WARN } from '@ctsolution/ct-framework';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-history-dialog',
  templateUrl: './history-dialog.component.html',
  styleUrls: ['./history-dialog.component.scss']
})
export class HistoryDialogComponent {
  formattedData: { label: string; content: string }[];
  cancelButton: CtButtonConfiguration = CtButtonConfiguration
  .create()
  .setClass('m-r-10')
  .setLabel("close")
  .setAction(() => this.dialogRef.close())
  .setMatherialOptions(MAT_RAISED_WARN)

  constructor(@Inject(MAT_DIALOG_DATA) public data: string, private translateService : TranslateService, private dialogRef: MatDialogRef<HistoryDialogComponent>) {
    this.formattedData = this.formatData(data);
  }
  formatData(data: string): { label: string; content: string }[] {

    return JSON.parse(data)
      .map((item: string) => item.trim())
      .filter((item: string) => item !== '') // Rimuove stringhe vuote
      .map((item: string) => {
        const parts = item.split(' to ');
        const label = parts[0].substring(0, parts[0].indexOf('[')).trim(); // Prende la label fino al primo '['
        let rightContent = parts[0].substring(parts[0].indexOf('[')).trim() + ' to ' + parts[1].trim(); // Include il contenuto destro dopo '['
  
        if (label === "Contratto specifico") {
          const [leftJson, rightJson] = rightContent.split(' to ').map(part => part.trim());
          const leftObject = JSON.parse(leftJson);
          const rightObject = JSON.parse(rightJson);
  
          // Funzione per formattare oggetti annidati senza indici per array
          const formatWithoutIndices = (obj: any): string => {
            return Object.entries(obj).map(([key, value]) => {
              if (Array.isArray(value)) {
                return `${this.translateService.instant(key)}:<br>${value.map(v => formatWithoutIndices(v)).join('<br>')}`;
              } else if (typeof value === 'object' && value !== null) {
                return `${this.translateService.instant(key)}:<br>${formatWithoutIndices(value)}`;
              } else {
                return `${this.translateService.instant(key)}: ${value !== null ? value : 'N/A'}`;
              }
            }).join('<br>');
          };
  
          const leftEntries = formatWithoutIndices(leftObject);
          const rightEntries = formatWithoutIndices(rightObject);
  
          rightContent = `${leftEntries} <br><hr><br>to<br> ${rightEntries}`;
        }
  
        // Rimuove tutte le parentesi quadre
        rightContent = rightContent.replace(/[\[\]]/g, '');
        return { label, content: rightContent.replace(/0:<br>/g, '') }; // Rimuove '0: ' se presente
      });
  }
} 