import {Component, OnInit} from "@angular/core";
import {Validators, UntypedFormGroup, UntypedFormBuilder} from "@angular/forms";
import {CtResetPasswordService} from "@ctsolution/ct-authentication";
import {CTGeneralService} from "@ctsolution/ct-framework";
import {MatLegacySnackBar} from "@angular/material/legacy-snack-bar";

@Component({
    selector: "app-forgot",
    templateUrl: "./forgot.component.html",
    styleUrls: ["./forgot.component.scss"]
})
export class ForgotComponent implements OnInit {

    public form: UntypedFormGroup = Object.create(null);

    constructor(private fb: UntypedFormBuilder, private snackbar: MatLegacySnackBar, private _general: CTGeneralService, private ctResetPasswordService: CtResetPasswordService) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            email: [null, Validators.compose([Validators.required, Validators.email])]
        });
    }

    onSubmit(): void {

        if (this.form.valid) {

            this.ctResetPasswordService
                .resetPassword(this.form.value.email)
                .subscribe(() => {

                    this.snackbar.open("Ti verrà inviato un messaggio di posta contenente un link per modificare la password di accesso.", "X", {duration: 3000});

                });

        }

    }

}
