import {Component} from '@angular/core';
import {CtControlConfiguration} from "@ctsolution/ct-framework";
import {AUTOMATIC_RENEW_CONTROL} from "./automatic-renew.control";

@Component({
    selector: 'app-automatic-renew-control',
    template: `
        <ct-control [configuration]="control"></ct-control>`
})
export class AutomaticRenewControlComponent {

    control: CtControlConfiguration = CtControlConfiguration.create(AUTOMATIC_RENEW_CONTROL);

}
