import {
    CtSerializerHelperService,
    CtWebapiService,
    DataRequest,
    MethodEnum
} from "@ctsolution/ct-webapi";
import {Injectable} from "@angular/core";
import {CtModelConfiguration, CTModelDatatableFilter} from "@ctsolution/ct-framework";
import {map} from "rxjs/operators";
import {ContractFile} from "../class/contract-file";
import {ControllerHelper} from "./controller.helper";
import {ContractDTO} from "../class/contract-dto";

const CONTROLLER: string[] = ["file"];
const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController(CONTROLLER);

@Injectable()
export class FileController {

    constructor(
        private _webapi: CtWebapiService,
        private serializerHelperService: CtSerializerHelperService,
        private _helper: ControllerHelper) {
    }

    get = <T>(filters: CTModelDatatableFilter[]): Promise<CtModelConfiguration<Array<ContractFile>>> => this._helper.list(CONTROLLER_REQUEST()
        .setBody({
            Filters: filters
        }));


    create(file: File, contract: ContractDTO<any>) {

        const parameter: ContractFile = new ContractFile(file, contract);

        const request: DataRequest = CONTROLLER_REQUEST()
            .setAction('Create')
            .setBody(this.serializerHelperService.objectToFormData(parameter))
            .setMethod(MethodEnum.POST);

        return this._webapi
            .request(request);

    }

    getContractPDF(file: ContractFile, contract: ContractDTO<any> | null, directDownload: boolean = false) {

        if (!file.Oid) return;

        const request: DataRequest = new DataRequest()
            .setController(['ct', 'File'])
            .setAction('Get')
            .setQueryParams({id: file.Oid, hideSpinner: true});

        return this._helper
            .getFile(request)
            .pipe(
                map(result => {

                    if (result) {

                        if (!file.Name) file.Name = `attachment`;

                        let fileName: string = file.Name.substring(0, file.Name.lastIndexOf('.')) || file.Name;

                        if (contract?.ContractCode) fileName += `-${contract.ContractCode}`;

                        if (directDownload) {

                            this.blobDownload(result, `ICOMM_${fileName}`);

                        }

                    }

                    return result;

                })
            );

    }

    delete(file: ContractFile) {

        if (!file.Oid) return null;

        const request: DataRequest = new DataRequest()
            .setController([...CONTROLLER, 'DeleteByOid'])
            .setAction(file.Oid.toString())
            .setMethod(MethodEnum.DELETE);

        return this._webapi
            .request(request);

    }

    blobDownload(blob: Blob, filename: string = `icomm_${new Date().getTime()}`) {

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        document.body.appendChild(a);

        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = filename;
        a.click();

        window.URL.revokeObjectURL(url);
        a.remove();

    }


}
