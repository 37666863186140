import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class EnumHelper {

  constructor() {
  }

  getEnumValues(enumType: any): string[] {
    return Object.keys(enumType)
      .filter(key => !isNaN(Number(enumType[key])))
      .map(key => enumType[key]);
  }

  getEnumLabel(enumType: any, value: any): string {
    const enumKeys = Object.keys(enumType).filter(key => !isNaN(Number(enumType[key])));
    const key = enumKeys.find(key => enumType[key] === value);
    return key ? key : '';
  }

}
