import {Component} from '@angular/core';
import {FormControl} from "@angular/forms";
import {CtButtonConfiguration, MAT_RAISED_PRIMARY, MAT_RAISED_WARN} from "@ctsolution/ct-framework";
import {MatLegacyDialogRef} from "@angular/material/legacy-dialog";

@Component({
    selector: 'app-notification-confirm-dialog',
    templateUrl: './notification-confirm-dialog.component.html',
    styleUrls: ['./notification-confirm-dialog.component.scss']
})
export class NotificationConfirmDialogComponent {

    control: FormControl = new FormControl<Date | null>(null);

    cancelButton: CtButtonConfiguration = CtButtonConfiguration
        .create()
        .setLabel("Annulla")
        .setAction(() => this.dialogRef.close())
        .setMatherialOptions(MAT_RAISED_WARN);

    confirmButton: CtButtonConfiguration = CtButtonConfiguration
        .create()
        .setLabel("Invia")
        .setAction(() => {

            this.dialogRef.close(this.control.value)

        })
        .setMatherialOptions(MAT_RAISED_PRIMARY);

    constructor(private dialogRef: MatLegacyDialogRef<NotificationConfirmDialogComponent>) {
    }


    close() {
    }

}
