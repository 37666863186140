import {MediaMatcher} from "@angular/cdk/layout";
import {Router} from "@angular/router";
import {ChangeDetectorRef, Component, OnDestroy, ViewChild} from "@angular/core";

import {TranslateService} from "@ngx-translate/core";
import {Direction} from "@angular/cdk/bidi";
import {CtAuthenticationService} from "@ctsolution/ct-authentication";
import {Subscription} from "rxjs";
import {MatSidenav} from "@angular/material/sidenav";
import {JwtService} from "../../core/lib/jwt.service";

/** @title Responsive sidenav */
@Component({
    selector: "app-full-layout",
    templateUrl: "full.component.html",
    styleUrls: []
})
export class FullComponent implements OnDestroy {

    private subscription?: Subscription;

    @ViewChild('snav') snav?: MatSidenav;

    $email: any;

    mobileQuery: MediaQueryList;

    dir: Direction = "ltr";
    dark = false;
    minisidebar = false;
    boxed = false;

    sidebarOpened = false;
    status = false;

    public selectedLanguage: any = {
        language: "English",
        code: "en",
        type: "US",
        icon: "us"
    };

    public languages: any[] = [
        {
            language: "English",
            code: "en",
            type: "US",
            icon: "us"
        },
        {
            language: "Español",
            code: "es",
            icon: "es"
        },
        {
            language: "Français",
            code: "fr",
            icon: "fr"
        },
        {
            language: "German",
            code: "de",
            icon: "de"
        }
    ];

    // tslint:disable-next-line - Disables all
    private _mobileQueryListener: () => void;

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public router: Router,
        private translate: TranslateService,
        private _jwt: JwtService,
        private _auth: CtAuthenticationService
    ) {

        translate.setDefaultLang("it");

        this.mobileQuery = media.matchMedia("(min-width: 1100px)");
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();

        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener);

        this.$email = this._jwt.getUserEmail();

        this.dark = false;

    }

    clickEvent(): void {

        this.status = !this.status;

    }

    darkClick() {

        const body = document.getElementsByTagName("body")[0];
        body.classList.toggle("dark");

    }

    changeLanguage(lang: any): void {

        this.translate.use(lang.code);
        this.selectedLanguage = lang;

    }

    logout() {

        this._auth
            .signOut()

    }

    toggleSidebar() {

        if (this.mobileQuery.matches) {

            this.minisidebar = !this.minisidebar

        } else {

            this.snav?.toggle();

        }

    }

    ngOnDestroy(): void {

        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener);
        this.subscription?.unsubscribe();

    }

}
