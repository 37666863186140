import {CtControlConfiguration, CtControlTypes, CtThemeTypes} from "@ctsolution/ct-framework";

export const MONITORING_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
    name: "Monitoring",
    label: "monitoring",
    theme: CtThemeTypes.MATERIAL,
    type: CtControlTypes.CHECKBOX
};

export const ALERT_MANAGEMENT_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
    name: "AlertManagement",
    label: "alertmanagement",
    theme: CtThemeTypes.MATERIAL,
    type: CtControlTypes.CHECKBOX
};

export const ALERT_EMAIL_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
    name: "AlertEmail",
    label: "alertemail",
    theme: CtThemeTypes.MATERIAL,
    type: CtControlTypes.TEXT
};
