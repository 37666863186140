import { AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { CtModelConfiguration } from '@ctsolution/ct-framework';
import { GeneralService } from "../../core/lib/general.service";
import { EnumHelper } from './../../core/lib/enum.helper';
import { REPORT_ROUTEDATA } from './report.module';

@Component({
  selector: 'app-report',
  template: `<ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`,
  styles: [
    ".text-right { text-align: right; }"
  ]
})
export class ReportComponent implements AfterViewInit {

  @ViewChild('legendTemplate') legendTemplate: TemplateRef<any> | null = null;
  @ViewChild('StateCellTemplate') stateCellTemplate: TemplateRef<any> | null = null;

  configuration: CtModelConfiguration<ReportComponent> | null = null;

  constructor( private cdr: ChangeDetectorRef, private general : GeneralService, private enumHelper: EnumHelper) { }

  ngAfterViewInit(): void {
    const configuration: CtModelConfiguration<ReportComponent> = CtModelConfiguration
    .create<ReportComponent>()
    .setRouteData(REPORT_ROUTEDATA())
    .setFieldsCustomTemplates(
      [
        {
          prop: 'EURString',
          cellClass: 'text-right'
        },
        {
          prop: 'CHFString',
          cellClass: 'text-right'
        }
      ]
    );

    this.configuration = configuration;

    this.cdr.detectChanges();
  }
}
