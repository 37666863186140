import {Injectable} from '@angular/core';
import {CtAuthenticationService} from "@ctsolution/ct-authentication";

@Injectable({
    providedIn: 'root'
})
export class JwtService {

    constructor(private CTAuthenticationService: CtAuthenticationService) {
    }

    async getUserEmail(): Promise<string | null> {

        await this.CTAuthenticationService.claimsUploaded();
        return this.CTAuthenticationService.getJwtValueByContainedKey('email', false);

    }

}
