import { Menu } from "./menu-item.interface";
import { CTBase } from "@ctsolution/ct-base";

export class CtThemeMenuConfiguration extends CTBase<CtThemeMenuConfiguration> {

  items: Menu[] = new Array<Menu>();

  createListElementClassByProperty(value: any, attribute: string) {

    switch (attribute) {

      case "items":
        return value as Menu;

    }

    super.createListElementClassByProperty(value, attribute);

  }

  private constructor();
  private constructor(model: CtThemeMenuConfiguration);
  private constructor(model?: CtThemeMenuConfiguration) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model: CtThemeMenuConfiguration): CtThemeMenuConfiguration => new CtThemeMenuConfiguration(model);

}
