import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";
import {ContractExtraFieldComponent} from "./contract-extra-field.component";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {CtButtonModule, CtFormContainerModule, CtModelModule} from "@ctsolution/ct-framework";

@NgModule({
    declarations: [ContractExtraFieldComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatCardModule,
        CtModelModule,
        CtFormContainerModule,
        CtButtonModule
    ],
    exports: [ContractExtraFieldComponent]
})
export class ContractExtraFieldModule {
}
