import {CtControlConfiguration, CtControlTypes, CtThemeTypes} from "@ctsolution/ct-framework";

export const VALIDITY_END_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
    name: "EndDate",
    label: "enddate",
    type: CtControlTypes.DATE,
    theme: CtThemeTypes.MATERIAL,
    validators: [
        {name: "required"}
    ]
}
