import {CtControlConfiguration, CtControlTypes, CtThemeTypes} from "@ctsolution/ct-framework";

export const OLD_CONTRACT_CODE_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
    name: "ParentContractContractCode",
    label: "ParentContractContractCode",
    theme: CtThemeTypes.MATERIAL,
    readonly: true,
    disabled: true
};

export const RENEWED_CONTRACT_CODE_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
    name: "ChildContractContractCode",
    label: "ChildContractContractCode",
    theme: CtThemeTypes.MATERIAL,
    readonly: true,
    disabled: true
};
