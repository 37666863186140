import {Injectable} from '@angular/core';
import {CtAuthenticationGuard, CtAuthenticationService} from "@ctsolution/ct-authentication";
import {Role} from "../enum/role";

type RoleChecker = () => Promise<boolean>;

interface IBeRole {
    administrator: RoleChecker;
    tech: RoleChecker;
    commercial: RoleChecker;
}

@Injectable({
    providedIn: 'root'
})
export class RoleService {
    constructor(
        private CTAuthenticationService: CtAuthenticationService,
        private CTAuthenticationGuard: CtAuthenticationGuard
    ) {
    }

    public is: IBeRole = {
        administrator: async () => {
            await this.CTAuthenticationService.claimsUploaded();
            return this.CTAuthenticationGuard.userHasRole([Role.ADMIN]);
        },
        tech: async () => {

            await this.CTAuthenticationService.claimsUploaded();


            /*
             * Modifica richiesta dal cliente: AB#9152
             * In certi casi, un utente può essere sia admin che tech.
             * Tuttavia, se ha entrambi i ruoli, il ruolo admin ha la priorità.
             * Per verificare che un utente sia effettivamente tech, è necessario
             * assicurarsi che abbia esclusivamente quel ruolo.
             */
            const roles = await this.CTAuthenticationGuard.getRoles();

            if (roles instanceof Array) {

                return roles.length === 1 && this.CTAuthenticationGuard.userHasRole([Role.TECH]); // Se l'array ha solo un elemento e quel elemento è Role.TECH

            } else {

                // Se roles non è un array ma un singolo ruolo
                return this.CTAuthenticationGuard.userHasRole([Role.TECH])

            }

        },
        commercial: async () => {
            await this.CTAuthenticationService.claimsUploaded();
            return this.CTAuthenticationGuard.userHasRole([Role.COMM]);
        }
    };

}
