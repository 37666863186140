import {AfterViewInit, Component, EventEmitter, Output} from '@angular/core';
import {CtControlConfiguration, CtSelectControlValue} from "@ctsolution/ct-framework";
import {CONTRACT_TYPE_CONTROL} from "./contract-type.control";
import {
    ContractTypeListElementDTO,
    ContractTypesController
} from "../../../../../core/controller/contract-types.controller";

@Component({
    selector: 'app-contract-type-control',
    template: `
        <ct-control
                (onChange)="updateDynamicForm()"
                [configuration]="contractTypeCtrl"></ct-control>`,
    providers: [ContractTypesController]
})
export class ContractTypeControlComponent implements AfterViewInit {

    @Output() setupContractTypeExtraField: EventEmitter<ContractTypeListElementDTO | null> = new EventEmitter<ContractTypeListElementDTO | null>();

    contractTypeCtrl: CtControlConfiguration = CtControlConfiguration.create(CONTRACT_TYPE_CONTROL);

    constructor(private contractTypeController: ContractTypesController) {
    }

    ngAfterViewInit() {

        this.setupContractTypeValues();

    }

    private setupContractTypeValues() {

        this.contractTypeController
            .getContractTypeLookup()
            .then((result: Array<ContractTypeListElementDTO>) => {

                const values: CtSelectControlValue[] = result.map(elm => CtSelectControlValue
                    .create()
                    .setLabel(elm.Description)
                    .setValue(elm));

                this.contractTypeCtrl
                    .setValueOptions(values)

            });

    }

    setContractTypeValueByOid(oid: number = 0) {

        const selectedOption: CtSelectControlValue | undefined = this.contractTypeCtrl
            ?.valueOptions
            ?.find(option => (option.value).Code === oid.toString());

        if (!selectedOption) return;

        this.contractTypeCtrl.setValue(selectedOption.value);

        this.updateDynamicForm();

    }

    updateDynamicForm() {

        this.setupContractTypeExtraField.emit(this.contractTypeCtrl.control?.value);

    }

}
