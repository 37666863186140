<h1 mat-dialog-title>Opzioni di esecuzione</h1>

<mat-dialog-content>

    <mat-form-field (click)="picker.open()">
        <mat-label>Data di invio</mat-label>
        <input [formControl]="control" matInput [matDatepicker]="picker">
        <mat-hint>GG/MM/AAAA</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions
        fxLayout="row wrap"
        fxLayoutAlign="space-between center">

    <ct-button [configuration]="cancelButton"></ct-button>

    <ct-button [configuration]="confirmButton"></ct-button>

</mat-dialog-actions>
