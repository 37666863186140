import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {NotificationConfirmDialogComponent} from "./notification-confirm-dialog.component";

@Injectable()
export class NotificationConfirmDialogService {

    constructor(private dialog: MatDialog) {
    }

    open = () => this.dialog.open(NotificationConfirmDialogComponent, { maxWidth: '500px'})

}
