import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {
    ContractTypeListElementDTO
} from "../../../../core/controller/contract-types.controller";
import {ContractCodeControlComponent} from "./contract-code-control/contract-code-control.component";
import {ContractTypeControlComponent} from "./contract-type-control/contract-type-control.component";

@Component({
    selector: 'app-contract-info-field',
    templateUrl: './contract-info-field.component.html'
})
export class ContractInfoFieldComponent implements AfterViewInit {

    @Output() setupContractTypeExtraField: EventEmitter<ContractTypeListElementDTO | null> = new EventEmitter<ContractTypeListElementDTO | null>();

    @ViewChild(ContractCodeControlComponent) contractCodeCtrl: ContractCodeControlComponent | null = null;
    @ViewChild(ContractTypeControlComponent) contractTypeCtrl: ContractTypeControlComponent | null = null;

    form: FormGroup;

    constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef) {

        this.form = this.formBuilder.group({});

    }

    ngAfterViewInit() {

        this.contractCodeCtrl
            ?.control
            .setFormParent(this.form);

        this.contractTypeCtrl
            ?.contractTypeCtrl
            .setFormParent(this.form);

        this.cdr.detectChanges();

    }


}
