import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StateLegendComponent} from "./state-legend.component";
import {StateBulletModule} from "./state-bullet/state-bullet.module";

@NgModule({
    declarations: [
        StateLegendComponent
    ],
    imports: [
        CommonModule,
        StateBulletModule
    ],
    exports: [StateLegendComponent]
})
export class StateLegendModule {
}
