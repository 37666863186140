import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {DashboardComponent} from "./dashboard.component";
import {CtCardModule, CtWidgetModule} from "@ctsolution/ct-framework";
import {MatDividerModule} from "@angular/material/divider";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {NotificationConfirmDialogModule} from "./notification-confirm-dialog/notification-confirm-dialog.module";

@NgModule({
    declarations: [
        DashboardComponent
    ],
    imports: [
        CommonModule,
        CtWidgetModule,
        MatDividerModule,
        CtCardModule,
        FlexLayoutModule,
        MatCardModule,
        NotificationConfirmDialogModule
    ]
})
export class DashboardModule {
}
