import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HistoryListModule } from './history-list/history-list.module';
import { HistoryListComponent } from './history-list/history-list.component';
import { CtModelRouteData } from '@ctsolution/ct-framework';
import { HistoryDialogModule } from './history-dialog/history-dialog.module';

@NgModule({
  imports: [
    CommonModule,
    HistoryListModule,
    HistoryDialogModule,
    RouterModule.forChild([
      {
        path: "",
        children: [
            {
                path: "",
                redirectTo: "list",
                pathMatch: "full"
            },
            {
                path: "list",
                component: HistoryListComponent
            }
        ]
    }
  ]),
  ],
})
export class HistoryModule { }

export const HISTORY_ROUTEDATA = (): CtModelRouteData => CtModelRouteData
    .create()
    .setController("history");
