import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FullComponent} from "./full.component";
import {AppBreadcrumbComponent} from "./breadcrumb/breadcrumb.component";
import {VerticalAppSidebarComponent} from "./vertical-sidebar/vertical-sidebar.component";
import {MatLegacyMenuModule as MatMenuModule} from "@angular/material/legacy-menu";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {MatIconModule} from "@angular/material/icon";
import {RouterModule, RouterOutlet} from "@angular/router";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatLegacySlideToggleModule as MatSlideToggleModule} from "@angular/material/legacy-slide-toggle";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CtFrameworkModule} from "@ctsolution/ct-framework";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective} from "./vertical-sidebar/accordion";
import {SpinnerModule} from "../spinner/spinner.module";
import {MenuItems} from "./menu/menu-items";

@NgModule({
    declarations: [FullComponent, AppBreadcrumbComponent, VerticalAppSidebarComponent, AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective],
    imports: [
        CommonModule,
        MatMenuModule,
        MatListModule,
        MatIconModule,
        RouterOutlet,
        MatSidenavModule,
        MatToolbarModule,
        MatSlideToggleModule,
        FormsModule,
        ReactiveFormsModule,
        CtFrameworkModule,
        RouterModule,
        FlexLayoutModule,
        MatButtonModule,
        MatIconModule,
        SpinnerModule
    ],
    exports: [FullComponent],
    providers: [MenuItems]
})
export class FullModule {
}
