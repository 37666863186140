export enum InvoiceType {

    MensileAnticipata = 1,
    BimestraleAnticipata = 2,
    TrimestraleAnticipata = 3,
    SemestraleAnticipata = 6,
    AnnualeAnticipata = 12,
    BiennaleAnticipata = 24,
    TriennaleAnticipata = 36,
    Unica = 0,
}
