import {
    CtModelConfiguration,
    CtModelRouteData,
    CtModelType
} from "@ctsolution/ct-framework";

export class CustomerHelper {

    private constructor() {
    }

    public static create = (): CustomerHelper => new CustomerHelper();

    getConfiguration(): CtModelConfiguration<CustomerHelper> {

        const routeData: CtModelRouteData =
            CtModelRouteData
                .create()
                .setController('customer')
                .setModelType(CtModelType.FORM);

        const configuration: CtModelConfiguration<any> | null = CtModelConfiguration
            .create()
            .setRouteData(routeData);

        return configuration;

    }

}
