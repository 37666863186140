import {CtControlConfiguration, CtControlTypes, CtThemeTypes} from "@ctsolution/ct-framework";

export const TOTAL_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
    name: "Total",
    label: "Total",
    theme: CtThemeTypes.MATERIAL,
    validators: [
        {name: "required"},
        {name: "min", value: 1}
    ],
    type: CtControlTypes.NUMBER
};
