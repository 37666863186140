import {Component} from '@angular/core';
import {
    CtControlConfiguration,
    CtControlValidator,
    CtFormContainerHelper,
    CtModelOnFlyCreateService,
    CtSelectControlOptions,
    CtThemeTypes
} from "@ctsolution/ct-framework";
import {CUSTOMER_LOCATION_CONTROL} from "./customer-location.control";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {CustomerLocationHelper} from "../../../../../core/lib/customer-location-helper";

@Component({
    selector: 'app-customer-location-control',
    template: `
        <ct-control [configuration]="control"></ct-control>`
})
export class CustomerLocationControlComponent {

    control: CtControlConfiguration = CtControlConfiguration
        .create(
            CtFormContainerHelper
                .create()
                .getCTFormControlByCTFieldInfo(CUSTOMER_LOCATION_CONTROL))
        .setTheme(CtThemeTypes.MATERIAL)
        .setValidators([CtControlValidator.create({name: 'required'} as CtControlValidator)])
        //.setDisabled(true)

    constructor(private onFlyCreateHelper: CtModelOnFlyCreateService) {
    }

    ngOnInit() {

        this.setup();

    }

    setup() {

        const options: CtSelectControlOptions | null = this.control.options as CtSelectControlOptions;

        if (!options) this.control.setOptions(CtSelectControlOptions.create());

        options
            .setEnableAddOptionButton(true)
            .setOnAddOptionButtonClick(() => new Promise<CtWebapiGenericResponse<number | string> | null>((resolve) => {

                const configuration = CustomerLocationHelper
                    .create()
                    .setCustomer(this?.control?.control?.value)
                    .getConfiguration()

                this.onFlyCreateHelper
                    .openOnFlyCreate(configuration)
                    .afterClosed()
                    .subscribe((result: CtWebapiGenericResponse<number | string> | null) => resolve(result))

            }));

    }

}
