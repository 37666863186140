import {AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewChild} from '@angular/core';
import {CtModelConfiguration} from "@ctsolution/ct-framework";
import {GeneralService} from "../../../core/lib/general.service";
import {WARRANTY_ROUTEDATA} from "../warranty.module";
import {StateBulletConfiguration} from "../../../components/state-legend/state-bullet/state-bullet.configuration";
import {StateLegendConfiguration} from "../../../components/state-legend/state-legend.configuration";
import {WarrantyState} from "../../../core/enum/warranty-state";
import {EnumHelper} from "../../../core/lib/enum.helper";

@Component({
    selector: 'app-warranty-list',
    templateUrl: './warranty-list.component.html'
})
export class WarrantyListComponent implements AfterViewInit {

    @ViewChild('legendTemplate') legendTemplate: TemplateRef<any> | null = null;
    @ViewChild('StateCellTemplate') stateCellTemplate: TemplateRef<any> | null = null;

    configuration: CtModelConfiguration<WarrantyListComponent> | null = null;

    constructor(
        private cdr: ChangeDetectorRef,
        public enumHelper: EnumHelper,
        private general: GeneralService) {
    }

    ngAfterViewInit() {

        const configuration: CtModelConfiguration<WarrantyListComponent> = CtModelConfiguration
            .create<WarrantyListComponent>()
            .setRouteData(WARRANTY_ROUTEDATA())
            .setOnCreate(() => this.general.navigateOnCTModelRouter(["warranty"], "create"))
            .setOnEdit(event => this.general.navigateOnCTModelRouter(["warranty"], "edit", {
                value: event,
                configuration: this.configuration!
            }))
            .setFieldsCustomTemplates([
                {
                    prop: 'State',
                    cellTemplate: this.stateCellTemplate
                },
                {
                    prop: 'Oid',
                    visible: false
                }
            ]);

        if (this.legendTemplate) configuration.setLegendTemplate(this.legendTemplate);

        this.configuration = configuration;

        this.cdr.detectChanges();

    }

    stateLabelPrefix = 'WARRANTY_STATE.';

    bulletStateConfiguration = (state: WarrantyState | string) => StateBulletConfiguration
        .create(
            this.enumHelper.getEnumLabel(WarrantyState, state))
        .setLabelPrefix(this.stateLabelPrefix)

    legendStateConfiguration = () => StateLegendConfiguration
        .create(WarrantyState)
        .setLabelPrefix(this.stateLabelPrefix);

}
