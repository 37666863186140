import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import {
    CtButtonConfiguration,
    CtControlConfiguration,
    MAT_RAISED_PRIMARY
} from "@ctsolution/ct-framework";
import { RoleService } from "../../../../core/lib/role.service";
import { OLD_CONTRACT_CODE_CONTROL, RENEWED_CONTRACT_CODE_CONTROL } from "./contract-renew.controls";

@Component({
    selector: 'app-contract-renew-field',
    templateUrl: './contract-renew-field.component.html',
    styleUrls: ['./contract-renew-field.component.scss']
})
export class ContractRenewFieldComponent {

    @Output() onRenew: EventEmitter<any> = new EventEmitter<any>();

    form: FormGroup;

    oldContractCodeCtrl: CtControlConfiguration = CtControlConfiguration.create(OLD_CONTRACT_CODE_CONTROL);
    renewedContractCodeCtrl: CtControlConfiguration = CtControlConfiguration.create(RENEWED_CONTRACT_CODE_CONTROL);

    renewButton: CtButtonConfiguration = CtButtonConfiguration
        .create()
        .setLabel("Sostituisci")
        .setAction(() => this.renew())
        .setMatherialOptions(MAT_RAISED_PRIMARY)

    viewModel = {
        isDisabled: false
    }

    constructor(private formBuilder: FormBuilder, private roleService: RoleService) {

        this.form = this.formBuilder.group({});

        this.oldContractCodeCtrl
            .setFormParent(this.form);

        this.renewedContractCodeCtrl
            .setFormParent(this.form);

        
        this.setupPermissions();

    }

    private async setupPermissions() {
        this.viewModel.isDisabled = (await this.roleService.is.tech()) == true;
        this.viewModel.isDisabled = this.viewModel.isDisabled;
        this.renewedContractCodeCtrl.control?.valueChanges.subscribe(() => {
            if(this.renewedContractCodeCtrl.control?.value) {
                this.viewModel.isDisabled = true;
            }
        });
    }

    renew() {

        this.onRenew.emit();

    }

}
