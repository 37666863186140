<mat-card>

    <mat-card-content [formGroup]="form">

        <mat-card-title>

            Rinnovo Contratto

        </mat-card-title>

        <div class="control-cntr">

            <ct-control [configuration]="oldContractCodeCtrl"></ct-control>

        </div>

        <div class="control-cntr">

            <ct-control [configuration]="renewedContractCodeCtrl"></ct-control>

        </div>

        <mat-card-actions
                *ngIf="!viewModel.isDisabled"
                align="end">

            <ct-button [configuration]="renewButton"></ct-button>

        </mat-card-actions>

    </mat-card-content>


</mat-card>
