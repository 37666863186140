export class StateBulletConfiguration {

    labelPrefix: string | null = null;

    private constructor(public state: string) {
    }

    public static create = (state: string) => new StateBulletConfiguration(state);

    setLabelPrefix(value: string | null) {

        this.labelPrefix = value;
        return this;

    }

}
