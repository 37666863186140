import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractRenewDialogComponent } from './contract-renew-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CtButtonModule } from '@ctsolution/ct-framework';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    ContractRenewDialogComponent
  ],
  imports: [
      CommonModule,
      MatDialogModule,
      MatDividerModule,
      MatIconModule,
      MatDatepickerModule,
      FormsModule,
      MatInputModule,
      ReactiveFormsModule,
      CtButtonModule,
      MatLegacyDialogModule,
      MatLegacyFormFieldModule,
      FlexModule
  ],
  exports:[ContractRenewDialogComponent]
})
export class ContractRenewDialogModule {
}
