import { Component } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import {
    CtBinaryOperator, CtControlConfiguration,
    CtModelConfiguration,
    CTModelDatatableFilter,
    CtModelOnFlyCreateService,
    CtModelRouteData,
    CtModelType, MateriaHintExtensionConfig
} from "@ctsolution/ct-framework";
import { CustomerLocation } from "../../../core/class/customer-location";
import { CustomerLocationHelper } from "../../../core/lib/customer-location-helper";
import { CUSTOMER_ROUTEDATA, LOCATION_ROUTEDATA } from "../customer.module";

@Component({
    selector: 'app-customer-edit',
    template: `
        <ct-model *ngIf="customerConfiguration" [configuration]="customerConfiguration"></ct-model>
        <ng-container *ngIf="enableLocationForm">

            <mat-divider></mat-divider>

            <h3> Ubicazioni </h3>

            <ct-model *ngIf="locationsModelConfiguration" [configuration]="locationsModelConfiguration"></ct-model>

        </ng-container>`
})
export class CustomerEditComponent {

    customerConfiguration: CtModelConfiguration<CustomerEditComponent> | null = null;
    locationsModelConfiguration: CtModelConfiguration<CustomerEditComponent> | null = null;

    constructor(private route: ActivatedRoute, private onFlyCreateHelper: CtModelOnFlyCreateService) {

        this.setupCustomerConfiguration();

        this.setupLocationConfiguration();

    }

    get enableLocationForm(): boolean {

        if (!this.customerConfiguration) return false;

        return !!this.customerConfiguration.RouteData?.id;

    }

    private setupCustomerConfiguration() {

        const routeData: CtModelRouteData = CUSTOMER_ROUTEDATA()
            .setModelType(CtModelType.FORM);

        const pId: string | null = this.route.snapshot.paramMap.get("id");

        if (pId) routeData.setId(+pId);

        this.customerConfiguration = CtModelConfiguration
            .create<CustomerEditComponent>()
            .setRouteData(routeData)
            .setFieldsCustomTemplates([
                {
                    prop: "ItContactName",
                    hint: MateriaHintExtensionConfig
                        .create()
                        .setMessage('Clicca qui per copiare i dati del referente amministrativo')
                        .setAction((control: CtControlConfiguration | null) => {

                            const form = control?.formParent;

                            if (!form) return;

                            const value = form.value;

                            form.patchValue({
                                ItContactName: value.AdminContactName,
                                ItMobilePhone: value.AdminMobilePhone,
                                ItEmailAddress: value.AdminEmailAddress
                            });

                        })
                }
            ]);


    }

    private setupLocationConfiguration() {

        const routeData: CtModelRouteData = LOCATION_ROUTEDATA()
            .setModelType(CtModelType.DATATABLE);

        const configuration = CtModelConfiguration
            .create<CustomerEditComponent>()
            .setRouteData(routeData)
            .setFilterable(false)
            .setFieldsCustomTemplates([
                {prop: "Customer", disabled: false},
                {
                    prop: "CustomerCompanyName",
                    visible: false
                }
            ])
            .setOnCreate(() => this.openLocationForm())
            .setOnEdit((location: CustomerLocation) => this.openLocationForm(location.Oid));

        configuration
            .filterValues
            .addConstantFilter(
                CTModelDatatableFilter
                    .create()
                    .setField('Customer.Oid')
                    .setValue(this.customerConfiguration?.RouteData?.id)
                    .setOperatorType(CtBinaryOperator.Equal)
            )

        this.locationsModelConfiguration = configuration;

    }

    private openLocationForm(Oid: number | null = null) {

        const configuration = CustomerLocationHelper
            .create()
            .setCustomer(this.customerConfiguration?.RouteData?.id ?? null)
            .setLocation(Oid)
            .getConfiguration()

        this.onFlyCreateHelper
            .openOnFlyCreate(configuration)
            .afterClosed()
            .subscribe((reload: any) => {

                if (reload) window.location.reload(); // dovrei usare la navigazione sul router, però non sta funzionando correttamente ... per ora facciamo refresh forzato

            });

    }

}
