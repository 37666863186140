import {CtControlConfiguration, CtControlTypes, CtThemeTypes} from "@ctsolution/ct-framework";

export const VALIDITY_START_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
    name: "StartDate",
    label: "startdate",
    type: CtControlTypes.DATE,
    theme: CtThemeTypes.MATERIAL,
    validators: [
        {name: "required"}
    ]
}
