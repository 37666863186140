import { Injectable } from '@angular/core';
import {
    CtAttachmentManagerConfiguration,
    CtAttachmentManagerService, CtBinaryOperator,
    CTModelDatatableFilter
} from "@ctsolution/ct-framework";
import { ContractDTO } from "../../core/class/contract-dto";
import { ContractFile } from "../../core/class/contract-file";
import { FileController } from "../../core/controller/file.controller";
import { RoleService } from "../../core/lib/role.service";

@Injectable()
export class ContractAttachmentsService {

    constructor(
        private attachmentManagerService: CtAttachmentManagerService,
        private fileController: FileController,
        private roleService: RoleService) {
    }

    async open(contract: ContractDTO<any>) {

        const isTech = await this.roleService.is.tech();

        const configuration: CtAttachmentManagerConfiguration<ContractFile> = CtAttachmentManagerConfiguration
            .create<ContractFile>()
            .setTitle(`Allegati Contratto ${contract.ContractCode}`)
            .setEmptyDataLabel('Non è stato caricato nessun documento per questo contratto.')
            .setNoteLabel('Clicca sul nome del file per scaricarlo')
            .enableOperations(!isTech)
            .setFileDownloader((file: ContractFile) => {

                this.fileController
                    .getContractPDF(file, contract)
                    ?.subscribe((blob: any) => {

                        if (blob) {

                            const fileURL = URL.createObjectURL(blob);
                            window.open(fileURL);

                        }

                    })

            })
            .setGetter(() => new Promise((resolve) => {

                const filter: CTModelDatatableFilter = CTModelDatatableFilter
                    .create()
                    .setField("Contract.Oid")
                    .setValue(contract.Oid)
                    .setOperatorType(CtBinaryOperator.Equal);

                this.fileController
                    .get([filter])
                    .then(result => resolve((result?.DataSource ?? []) as Array<ContractFile>))

            }))
            .setFileDeleteFunc((value: ContractFile) => this.fileController.delete(value))
            .setFileUploadFunc((value: File) => this.fileController.create(value, contract))
            .setFileNameMapper((value) => value.Name)

        configuration.fileUploaderConfiguration.format.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        configuration.fileUploaderConfiguration.format.push('application/vnd.ms-excel');
        configuration.fileUploaderConfiguration.format.push('text/csv');

        return this.attachmentManagerService
            .open<ContractFile>(configuration);

    }

}
