import {CtSelectControlValue} from "@ctsolution/ct-framework";
import {Currency} from "../../../../../core/enum/currency";

export const CONTRACT_CURRENCY: CtSelectControlValue[] = [
    CtSelectControlValue
        .create()
        .setLabel('EUR')
        .setValue(Currency.EUR),
    CtSelectControlValue
        .create()
        .setLabel('CHF')
        .setValue(Currency.CHF)
];
