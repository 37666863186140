import { CTFieldInfos } from "@ctsolution/ct-framework";
import { environment } from "../../../../../../environments/environment";

export const COMMERCIAL_CONTROL: CTFieldInfos = CTFieldInfos.create({
    Field: "User",
    ControlType: "Lookup",
    LookupInfos: {
        RemoteEndpoint: `${environment.dev.REST_API_SERVER}/User/CommercialLookup`
    }
} as CTFieldInfos)
