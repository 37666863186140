import {BrowserModule} from "@angular/platform-browser";
import {LOCALE_ID, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {HttpClientModule, HttpClient} from "@angular/common/http";
import {DatePipe} from "@angular/common";
import {AppRoutes} from "./app.routing";
import {AppComponent} from "./app.component";

import {FlexLayoutModule} from "@angular/flex-layout";

import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {FullModule} from "./layouts/full/full.module";
import {BlankModule} from "./layouts/blank/blank.module";
import {
    CtFrameworkModule,
    CtModelHttpLoader,
    CtModelLoader,
    CtModelModule,
} from "@ctsolution/ct-framework";
import {CtAuthenticationModule, CtAuthenticationSetup} from "@ctsolution/ct-authentication";
import {CtWebapiModule, CtWebapiSetup} from "@ctsolution/ct-webapi";
import {environment} from "../environments/environment";
import {SpinnerModule} from "./layouts/spinner/spinner.module";
import {DEFAULT_DATATABLE_CONFIGURATION} from "./core/constants";

import {registerLocaleData} from '@angular/common';
import localeIT from '@angular/common/locales/it';
import {NgxWebstorageModule} from "ngx-webstorage";

registerLocaleData(localeIT, 'it-IT');

export function HttpLoaderFactory(http: HttpClient): any {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function CtModelHttpLoaderFactory(http: HttpClient) {
    return new CtModelHttpLoader(http, './assets/ct-model/', '.json');
}

const AUTHENTICATION_CONFIG = CtAuthenticationSetup
    .create(environment.projectName, environment.dev.REST_API_SERVER)
    .setRedirectUrl("/authentication/login");

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        FlexLayoutModule,
        HttpClientModule,
        FullModule,
        BlankModule,
        RouterModule.forRoot(AppRoutes),
        CtFrameworkModule.forRoot(),
        CtAuthenticationModule.setup(AUTHENTICATION_CONFIG),
        CtWebapiModule.setup(
            CtWebapiSetup
                .create(environment.dev.REST_API_SERVER)
        ),
        HttpClientModule,
        SpinnerModule,
        NgxWebstorageModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CtModelModule.forRoot({
            projectName: environment.projectName,
            loader: {
                provide: CtModelLoader,
                useFactory: CtModelHttpLoaderFactory,
                deps: [HttpClient]
            },
            datatableConfig: DEFAULT_DATATABLE_CONFIGURATION()
        })
    ],
    providers: [
        DatePipe,
        {provide: LOCALE_ID, useValue: "it-IT"}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
