import {Component} from '@angular/core';
import {
    CtControlConfiguration,
    CtControlValidator,
    CtFormContainerHelper, CtModelDatatableOperators,
    CtModelOnFlyCreateService,
    CtSelectControlOptions,
    CtThemeTypes,
    MateriaHintExtensionConfig
} from "@ctsolution/ct-framework";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {CustomerHelper} from "../../../../../core/lib/customer-helper";
import {CUSTOMER_CONTROL} from "./customer.control";

@Component({
    selector: 'app-customer-control',
    template: `
        <ct-control [configuration]="control"></ct-control>`
})
export class CustomerControlComponent {

    control: CtControlConfiguration = CtControlConfiguration
        .create(
            CtFormContainerHelper
                .create()
                .getCTFormControlByCTFieldInfo(CUSTOMER_CONTROL))
        .setTheme(CtThemeTypes.MATERIAL)
        .setValidators([
            CtControlValidator
                .create({name: 'required'} as CtControlValidator)
        ]);

    constructor(private onFlyCreateHelper: CtModelOnFlyCreateService) {
    }

    ngOnInit() {

        this.setup();

    }

    setup() {

        const options: CtSelectControlOptions | null = this.control.options as CtSelectControlOptions;

        if (!options) this.control.setOptions(CtSelectControlOptions.create());

        options
            .setEnableAddOptionButton(true)
            .setLookupFilter(() => CtModelDatatableOperators
                    .create()
                    .Pagination
                    ?.setElementsForPage(200)
                // ?.setSortOrders([CtSortOrderDescriptorParameter.create('CompanyName').setIsDescending(false)]) // TODO: dovrebbe ordinarlo l'API, ma essendo una lookup custom penso che non sia configurato al momento. Li ordino io ma in futuro sarebbe meglio attivare l'ordinamento lato API
            )
            .setLookupResponseOrderBy(response => {

                (<Array<any>>(<any>response.Result).DataSource).sort((a, b) => a.Description.localeCompare(b.Description));

                return response;

            })
            .setOnAddOptionButtonClick(() => new Promise<CtWebapiGenericResponse<number | string> | null>((resolve) => {

                const configuration = CustomerHelper
                    .create()
                    .getConfiguration()
                    .setFieldsCustomTemplates([
                        {
                            prop: "ItContactName",
                            hint: MateriaHintExtensionConfig
                                .create()
                                .setMessage('Clicca qui per copiare i dati del referente amministrativo')
                                .setAction((control: CtControlConfiguration | null) => {

                                    const form = control?.formParent;

                                    if (!form) return;

                                    const value = form.value;

                                    form.patchValue({
                                        ItContactName: value.AdminContactName,
                                        ItMobilePhone: value.AdminMobilePhone,
                                        ItEmailAddress: value.AdminEmailAddress
                                    });

                                })

                        }
                    ]);

                this.onFlyCreateHelper
                    .openOnFlyCreate(configuration)
                    .afterClosed()
                    .subscribe((result: CtWebapiGenericResponse<number | string> | null) => resolve(result))

            }));

    }

}
