<mat-card>

    <mat-card-content>

        <mat-card-title>

            Alert e monitoraggio

        </mat-card-title>

        <div>

            <form
                    [fxLayout]="'row wrap'"
                    [formGroup]="form">

                <div class="control-cntr checkbox-cntr"

                     [fxFlex]="50"
                     [fxFlex.md]="100"
                     [fxFlex.sm]="100"
                     [fxFlex.xs]="100"
                     [fxLayout]="'column'">

                    <ct-control [configuration]="monitoringCntrl"></ct-control>

                    <ct-control [configuration]="alertManagementCntrl"></ct-control>

                </div>

                <div class="control-cntr"

                     [fxFlex]="50"
                     [fxFlex.md]="100"
                     [fxFlex.sm]="100"
                     [fxFlex.xs]="100">

                    <ct-control [configuration]="alertEmailCntrl"></ct-control>

                </div>

            </form>

        </div>

    </mat-card-content>

</mat-card>
