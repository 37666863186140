import {
    CtBinaryOperator,
    CtModelConfiguration,
    CTModelDatatableFilter,
    CtModelRouteData,
    CtModelType
} from "@ctsolution/ct-framework";

export class CustomerLocationHelper {

    public LocationOid: number | null = null;
    public CustomerOid: number | null = null;

    private constructor() {
    }

    public static create = (): CustomerLocationHelper => new CustomerLocationHelper();

    setLocation(value: number | null): CustomerLocationHelper {

        this.LocationOid = value;
        return this;

    }

    setCustomer(value: number | null): CustomerLocationHelper {

        this.CustomerOid = value;
        return this;

    }

    getConfiguration(): CtModelConfiguration<CustomerLocationHelper> {

        const routeData: CtModelRouteData =
            CtModelRouteData
                .create()
                .setController('location')
                .setModelType(CtModelType.FORM);

        if (this.LocationOid) {

            routeData
                .setId(this.LocationOid);

        }

        const configuration: CtModelConfiguration<any> | null = CtModelConfiguration
            .create()
            .setRouteData(routeData);

        if (this.CustomerOid) {

            configuration
                .filterValues
                .addConstantFilter(
                    CTModelDatatableFilter
                        .create()
                        .setField('Customer')
                        .setValue(this.CustomerOid)
                        .setOperatorType(CtBinaryOperator.Equal)
                );

        }

        return configuration;

    }

}
